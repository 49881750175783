import { ReactNode } from 'react'

export const ContentBox = ({
  children,
  padding = 'py-3.5 px-3 md:py-4 md:px-6',
}: {
  children: ReactNode
  padding?: string
}) => {
  return (
    <div className={`${padding} relative bg-ghostWhite rounded-2xl flex flex-col h-fit w-full shadow-contentBox`}>
      {children}
    </div>
  )
}
