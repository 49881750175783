import { AutocompleteInput, Button, Input, InputType, Spin } from '../components'
import { handleError } from '../utils'
import { FormEvent, useCallback, useContext, useState } from 'react'
import { ButtonTypes } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../appRoutes'
import { postReports } from './postReports'
import { SMEContext, SocialMediaPlatforms, socialMedia } from './sme-context'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GlobeIcon } from '../icons/globe.svg'

export const SMELinkPage = () => {
  const [formValues, setFormValues] = useState<Record<SocialMediaPlatforms, string>>({
    ig: '',
    tiktok: '',
    twitter: '',
    youtube: '',
    linkedin: '',
  })
  const [formLabels, setFormLabels] = useState<Record<string, string>>({
    'ig-label': '',
    'tiktok-label': '',
    'twitter-label': '',
    'youtube-label': '',
    'linkedin-label': '',
  })
  const { setData, setCompany } = useContext(SMEContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      setLoading(true)
      setCompany(
        formLabels['tiktok-label'] ||
          formLabels['ig-label'] ||
          formLabels['linkedin-label'] ||
          formLabels['twitter-label'] ||
          formLabels['youtube-label']
      )
      return postReports(formValues)
        .then((result) => {
          if (result?.data && Object.values(result.data).every((value) => !value)) {
            alert(
              t('noDataFor', {
                company:
                  formValues.tiktok || formValues.ig || formValues.linkedin || formValues.twitter || formValues.youtube,
              })
            )
          }
          setData(result?.data)
        }, handleError)
        .finally(() => {
          setLoading(false)
          navigate(AppRoutes.sme)
        })
    },
    [setCompany, formValues, formLabels, setData, navigate, t]
  )

  return (
    <div className="max-w-[840px] flex flex-col items-center relative">
      <h2 className="text-primary text-[1.75rem] text-center mb-9">{t('aixplorDescription')}</h2>
      <form className="w-[360px]" onSubmit={handleSubmit}>
        {socialMedia.map((platform) =>
          [SocialMediaPlatforms.ig, SocialMediaPlatforms.linkedin, SocialMediaPlatforms.tiktok].includes(platform) ? (
            <Input
              key={platform}
              icon={<GlobeIcon />}
              label={t(platform)}
              type={InputType.text}
              name={platform}
              value={formValues[platform]}
              onChange={(event) => {
                setFormValues({ ...formValues, [platform]: event.target.value })
                setFormLabels({ ...formLabels, [`${platform}-label`]: event.target.value })
              }}
              labelBg="before:bg-[#E9E1ED]"
              placeholder={t(platform)}
              bottomInfo={t('provideExactId')}
            />
          ) : (
            <AutocompleteInput
              key={platform}
              platform={platform}
              name={platform}
              selectedValue={{ label: formLabels[`${platform}-label`], value: formValues[platform] }}
              onSelect={(value: { label: string; value: string }) => {
                setFormValues({ ...formValues, [platform]: value.value })
                setFormLabels({ ...formLabels, [`${platform}-label`]: value.label })
              }}
            />
          )
        )}
        {loading ? (
          <div className="flex justify-end">
            <Spin />
          </div>
        ) : (
          <Button
            buttonType={ButtonTypes.primary}
            title={t('check')}
            styles="flex justify-end"
            type="submit"
            disabled={Object.values(formValues).every((value) => !value)}
          />
        )}
      </form>
    </div>
  )
}
