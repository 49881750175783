import { Link } from 'react-router-dom'
import { AppRoutes } from '../appRoutes'
import { ReactComponent as LogoIcon } from '../icons/white-logo.svg'
import { useTranslation } from 'react-i18next'

export const RecommendationButton = () => {
  const { t } = useTranslation()
  return (
    <Link
      to={AppRoutes.recommendation}
      className="p-4 pt-7 rounded-[2rem] bg-[#C837AB] flex flex-col justify-center items-center gap-4 fixed bottom-2 right-2 md:bottom-[50px] md:right-[50px] shadow-recommendationButton hover:bg-primary hover:shadow-button hover:scale-sc-button-hover"
    >
      <LogoIcon />
      <p className="font-medium text-white text-xs md:text-base">{t('buttonRecommendation')}</p>
    </Link>
  )
}
