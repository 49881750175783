import { useState } from 'react'
import { ReactComponent as DropdownIcon } from '../icons/dropdownIcon.svg'
import { useOutsideClick } from '../utils'
import { useTranslation } from 'react-i18next'

export const DropdownCompetitors = ({
  selectedValue,
  values,
  onSelect,
}: {
  selectedValue: string
  values: string[]
  onSelect: (value: string) => void
}) => {
  const [valueSelected, setSelectedValue] = useState<string>(selectedValue)
  const [isDropped, setIsDropped] = useState(false)
  const clickOutside = useOutsideClick(() => setIsDropped(false))
  const { t } = useTranslation()

  return (
    <div className="relative flex gap-3">
      <p className="text-[1.75rem] leading-8 text-secondary font-medium">{t('vs')}</p>
      <button
        onClick={() => {
          setIsDropped((dropped) => !dropped)
        }}
        id="dropdownDividerButton"
        data-dropdown-toggle="dropdownDivider"
        className="min-w-[100px] min-h-[35px] flex flex-row items-center justify-between"
      >
        <p className="text-2xl md:text-[1.75rem] leading-7 text-secondary md:font-medium">{valueSelected}</p>
        <DropdownIcon className="ml-2" />
      </button>
      {isDropped && (
        <div
          ref={clickOutside}
          id="dropdownDivider"
          className="absolute mt-[10px] right-0 top-6 z-10 rounded-[16px] overflow-hidden shadow-dialog"
        >
          <ul aria-labelledby="dropdownDividerButton" className="w-[100%]">
            {values.map((value) => {
              const select = () => {
                setSelectedValue(value)
                onSelect(value)
                setIsDropped(false)
              }

              return (
                <li key={value}>
                  <button
                    onClick={select}
                    className="bg-white hover:bg-ghostWhite w-full min-h-[35px] items-center p-[10px]"
                  >
                    <p className="text-dropDownText text-[14px] m-w-[75px] text-left">{value}</p>
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}
