import { useContext } from 'react'
import { SMEContext } from '../../sme/sme-context'
import { SelectableIcon } from '../selectableIcons/selectableIcon'

export enum SocialMedia {
  youtube = 'youtube',
  instagram = 'instagram',
  linkedin = 'linkedin',
  twitter = 'twitter',
  facebook = 'facebook',
  tiktok = 'tiktok',
}

export const SelectorSocialMedia = ({
  selectedSocialMedia,
  setSelectedSocialMedia,
}: {
  selectedSocialMedia: string
  setSelectedSocialMedia: (value: string) => void
}) => {
  const { data } = useContext(SMEContext)
  const availableSocialMedia = data && Object.keys(data)

  return (
    <div className="flex flex-row items-center gap-6 flex-wrap">
      {availableSocialMedia?.map((media) => (
        <button key={media} onClick={() => setSelectedSocialMedia(media)}>
          <SelectableIcon platform={media} isSelected={selectedSocialMedia === media} />
        </button>
      ))}
    </div>
  )
}
