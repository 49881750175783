import { ReactNode, createContext, useState } from 'react'
import { User } from './fetchUsers'

type ExtendedUser = User & { toDelete?: boolean }

export const UsersContext = createContext<{
  users: ExtendedUser[]
  selectedUser?: ExtendedUser
  setUsers: (users: User[]) => void
  setSelectedUser: (user?: ExtendedUser) => void
}>({
  users: [],
  selectedUser: undefined,
  setUsers: () => {},
  setSelectedUser: () => {},
})

export const UsersProvider = ({ children }: { children: ReactNode }) => {
  const [users, storeUsers] = useState<User[]>([])
  const [selectedUser, storeUser] = useState<User | undefined>()

  const setUsers = (users: User[]) => {
    try {
      storeUsers(users)
    } catch (error) {
      console.error(error)
    }
  }

  const setSelectedUser = (user?: ExtendedUser) => {
    try {
      storeUser(user || undefined)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <UsersContext.Provider value={{ users, selectedUser, setUsers, setSelectedUser }}>{children}</UsersContext.Provider>
  )
}
