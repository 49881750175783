import { Button, AuthContentBox, Input, InputType, Spin } from '../components'
import { handleError } from '../utils'
import { emailPattern, passwordPattern } from './patterns'
import { ReactComponent as AccountIcon } from '../icons/account.svg'
import { ReactComponent as LockIcon } from '../icons/lock.svg'
import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { ButtonTypes } from '../components/Button'
import { postCreateAccount } from './postCreateAccount'
import { useLogIn } from './useLogIn'
import { useTranslation } from 'react-i18next'

export const NewAccountPage = () => {
  const [formValues, setFormValues] = useState({ email: '', password: '' })
  const logIn = useLogIn(formValues)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      setLoading(true)
      return postCreateAccount(formValues)
        .then(logIn, handleError)
        .finally(() => {
          setLoading(false)
        })
    },
    [formValues, logIn]
  )

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setFormValues({ ...formValues, [event.target.name]: event.target.value }),
    [formValues]
  )

  return (
    <AuthContentBox title={t('newAccount')}>
      <form className="w-[360px]" onSubmit={handleSubmit}>
        <Input
          icon={<AccountIcon />}
          type={InputType.email}
          label={t('emailAddress')}
          name="email"
          required
          pattern={emailPattern}
          value={formValues.email}
          onChange={handleOnChange}
        />
        <Input
          icon={<LockIcon />}
          type={InputType.password}
          label={t('password')}
          name="password"
          required
          pattern={passwordPattern}
          value={formValues.password}
          onChange={handleOnChange}
          error={t('pleaseEnterCorrectPassword')}
        />
        {loading ? (
          <div className="flex justify-end">
            <Spin />
          </div>
        ) : (
          <Button
            buttonType={ButtonTypes.primary}
            title={t('createNewAccount')}
            styles={'flex justify-end'}
            type="submit"
            disabled={Object.values(formValues).some((value) => !value)}
          />
        )}
      </form>
    </AuthContentBox>
  )
}
