import { useContext } from 'react'
import { apiUrls } from '../api/api-urls'
import { fetchMutation, handleError } from '../utils'
import { UsersContext } from './users-context'

export const useDeleteUser = () => {
  const { users, setUsers, setSelectedUser } = useContext(UsersContext)
  return (id: string) =>
    fetchMutation(`${apiUrls.users}/${id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
      setUsers(users.filter((user) => user.id !== id))
      setSelectedUser(undefined)
    }, handleError)
}
