import { ReactNode } from 'react'

type Props = { title: string; children: ReactNode }

export const PageContainer = ({ title, children }: Props) => (
  <div className="w-full max-w-3xl">
    <h2 className="mb-4 mt-5 text-[1.5rem] md:text-[2rem] text-primary">{title}</h2>
    {children}
  </div>
)
