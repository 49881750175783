import { Button, ButtonTypes, AuthContentBox, Input, InputType, Spin } from '../components'
import { handleError } from '../utils'
import { emailPattern } from './patterns'
import { ReactComponent as AccountIcon } from '../icons/account.svg'
import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { postForgotPassword } from './postForgotPassword'
import { useTranslation } from 'react-i18next'

export const ForgotPasswordPage = () => {
  const [formValues, setFormValues] = useState({ email: '' })
  const [isSending, setIsSending] = useState(false)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      setIsSending(true)
      postForgotPassword(formValues.email)
        .then(
          () => setIsSending(true),
          (error) => {
            handleError(error)
            setIsSending(false)
          }
        )
        .finally(() => {
          setLoading(false)
        })
    },
    [formValues.email]
  )

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setFormValues({ ...formValues, [event.target.name]: event.target.value }),
    [formValues]
  )

  return (
    <AuthContentBox title={t('passwordReset')}>
      <p className="text-xs mb-4 max-w-[440px]">{t('passwordResetDescription')}</p>
      <form className="w-[360px] max-w-[360px]" onSubmit={handleSubmit}>
        <Input
          icon={<AccountIcon />}
          type={InputType.email}
          label={t('emailAddress')}
          name="email"
          required
          pattern={emailPattern}
          value={formValues.email}
          onChange={handleOnChange}
        />
        {loading ? (
          <div className="flex justify-end">
            <Spin />
          </div>
        ) : (
          <Button
            buttonType={ButtonTypes.primary}
            title={t('reset')}
            styles="flex justify-end"
            type="submit"
            disabled={Object.values(formValues).some((value) => !value) || isSending}
          />
        )}
      </form>
    </AuthContentBox>
  )
}
