import { useTranslation } from 'react-i18next'
import { socialMediaIcons } from '../components'

export const TopKeywords = ({ data }: { data: { company: string; value: string[] }[] }) => {
  const { t } = useTranslation()
  console.log(data)
  return (
    <div className="ml-2 md:-ml-2">
      {data.map(({ company, value }, index) => (
        <div key={index} className="flex gap-8 mb-5">
          <div className="min-w-[40px]">{socialMediaIcons()[company]}</div>
          <div className="flex gap-1 flex-wrap">
            {value
              ? value.length === 0
                ? t('noKeywords')
                : value.map((keyword: string, idx: number) => (
                    <div
                      key={idx}
                      className="rounded-lg bg-[#F9F2F6] shadow-keywords px-4 py-1.5 text-sm font-medium flex items-center"
                    >
                      {keyword}
                    </div>
                  ))
              : t('noOrWrongId')}
          </div>
        </div>
      ))}
    </div>
  )
}
