import { apiUrls } from '../api/api-urls'
import { AuthenticationKey } from '../auth'

export type FetchResult<T> = { data: T; status: number } | undefined

const BASE_API_URL = process.env.REACT_APP_API_URL
const NEW_API_URL = process.env.REACT_APP_API_REPORTS_URL

export const fetchMutation: <T>(url: string, config: RequestInit, newApi?: boolean) => Promise<FetchResult<T>> = async (
  url,
  config,
  newApi
) => {
  const unprotectedPaths = [apiUrls.login, apiUrls.forgotPassword]
  const unprotectedPathsPrefix = unprotectedPaths.filter((prefix) => url.includes(prefix))[0]
  const bearerToken = window.localStorage.getItem('token')
  try {
    const response = await fetch((newApi ? NEW_API_URL : BASE_API_URL) + url, {
      ...config,
      headers: unprotectedPathsPrefix
        ? { ...config.headers }
        : { Authorization: `Bearer ${bearerToken}`, ...config.headers },
    })
    const text = await response.text()
    let data
    try {
      data = JSON.parse(text)
    } catch (e) {
      data = text
    }
    if (response.status === 401) {
      window.localStorage.removeItem(AuthenticationKey.token)
    }
    if (response.ok) {
      return { data, status: response.status }
    }
    throw new Error(data.message)
  } catch (e: any) {
    throw new Error(e)
  }
}
