import { useTranslation } from 'react-i18next'
import { socialMediaIcons } from '../components'

export const SMESummaryItem = ({ data }: { data: any }) => {
  const { t } = useTranslation()
  return (
    <div className="bg-ghostWhite rounded-3xl shadow-contentBox px-4 pl-2.5 lg:px-8 py-4 flex gap-7 mb-7">
      <div className="flex items-center">{socialMediaIcons(50)[data.company]}</div>
      <p>{typeof data.value === 'object' ? t('noSummary') : data.value}</p>
    </div>
  )
}
