import { ReactNode } from 'react'
import { ReactComponent as LogoShort } from '../icons/logo-short.svg'

export const AuthContentBox = ({
  children,
  title,
  padding = 'p-11',
  noLogo,
}: {
  children: ReactNode
  title: ReactNode
  padding?: string
  noLogo?: boolean
}) => {
  return (
    <div
      className={`${padding} relative bg-ghostWhite rounded-2xl flex flex-col items-center h-fit sm:w-[552px] w-full`}
    >
      {noLogo ? null : <LogoShort />}
      {typeof title === 'string' ? <h2 className="mb-4 mt-5 text-[2rem] text-center text-primary">{title}</h2> : title}
      {children}
    </div>
  )
}
