import dayjs from 'dayjs'
import { apiUrls } from '../api/api-urls'
import { fetchMutation } from '../utils'

export const fetchUsers = async (): Promise<User[]> => {
  return fetchMutation(apiUrls.users, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then(
    (response: any) => {
      const users = response?.data?.data?.map((user: any) => new User(user))
      return Promise.resolve(users)
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export class User {
  id = ''
  firstname = ''
  lastname = ''
  email = ''
  start_date = ''
  end_date = ''
  expired = false

  constructor(data: any) {
    if (!data) {
      return
    }

    if (data.id) {
      this.id = data.id
    }

    if (data.lastname) {
      this.lastname = data.lastname
    }

    if (data.firstname) {
      this.firstname = data.firstname
    }
    if (data.email) {
      this.email = data.email
    }
    if (data.start_date) {
      this.start_date = dayjs(data.start_date).format('YYYY-MM-DDThh:mm:ss')
    }
    if (data.end_date) {
      this.end_date = dayjs(data.end_date).format('YYYY-MM-DDThh:mm:ss')
    }
    if (data.expired) {
      this.expired = data.expired
    }
  }
}
