export const apiUrls = {
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  newPassword: '/account/password',
  createAccount: '/auth/signup',
  reports: '/reports',
  recommendations: '/reports/recommendations',
  users: '/users',
}
