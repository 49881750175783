import { Button, ButtonTypes, ContentBox, Heading, SelectorSocialMedia, Spin } from '../components'
import { handleError } from '../utils'
import { RecommendationItem } from './item-recommendation'
import { AppRoutes } from '../appRoutes'
import { useCallback, useContext, useEffect, useState } from 'react'
import { RecommendationChart } from './recommendation-chart'
import { getRecommendations } from './getRecommendations'
import { SMEContext } from '../sme/sme-context'
import { SocialMedia } from '../components/selectorSocialMedia/selectorSocialMedia'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type Recommendation = {
  title: string
  description: string
}

export type Outcomes = {
  subscribers: number
  views: number
  likes: number
  summary: string
}

export type RecommendationData = {
  expected_outcomes: Outcomes
  recommendations: Recommendation[]
  summary: string
}

export const RecommendationPage = () => {
  const { company, data: smeData } = useContext(SMEContext)
  const [selectedSocialMedia, setSelectedSocialMedia] = useState(
    smeData ? Object.keys(smeData)[0] : SocialMedia.youtube
  )
  const [data, setData] = useState<RecommendationData | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const fetchRecommendations = useCallback(() => {
    setLoading(true)
    getRecommendations(selectedSocialMedia)
      .then((result) => setData(result?.data), handleError)
      .finally(() => setLoading(false))
  }, [selectedSocialMedia])

  useEffect(fetchRecommendations, [fetchRecommendations])

  if (!smeData) {
    return <Navigate to={AppRoutes.home} />
  }

  return (
    <div className="w-full">
      <ContentBox>
        <Heading>{`${company} ${t('recommendation')}`}</Heading>
        <div className="lg:px-10 flex items-center flex-wrap mt-4 gap-4 md:mt-[30px]">
          <p className="items-center text-sm md:text-[22px] text-primary">{t('detailedRecommendation')}</p>
          <div className="flex justify-center w-full lg:w-auto">
            <SelectorSocialMedia
              selectedSocialMedia={selectedSocialMedia}
              setSelectedSocialMedia={setSelectedSocialMedia}
            />
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center w-full my-8">
            <Spin />
          </div>
        ) : (
          data && (
            <div className="w-full mt-[20px] md:px-10">
              <div>
                {data.recommendations.map((recommendation, index) => (
                  <RecommendationItem key={index} recommendation={recommendation} index={index + 1} />
                ))}
                <p className="mt-[30px] md:text-[22px] text-primary">{t('expectedOutcomes')}</p>
                <div className="my-[14px] md:my-[30px]">
                  <RecommendationChart data={data.expected_outcomes} />
                </div>

                <p className="md:text-[22px] text-primary">{t('summary')}</p>
                <div className="mb-7 md:mb-[90px] mt-4 md:mt-6">
                  <ContentBox padding="px-3 py-4 md:px-6 md:py-8">
                    <p className="text-secondary text-xs md:text-base">{data.expected_outcomes.summary}</p>
                  </ContentBox>
                </div>
              </div>
            </div>
          )
        )}
        <div className="px-10"></div>
      </ContentBox>
      <Button
        buttonType={ButtonTypes.secondary}
        title={t('goBackToSme')}
        type="button"
        to={AppRoutes.sme}
        styles="flex justify-center mb-[60px] mt-12"
      />
    </div>
  )
}
