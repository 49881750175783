import { Button, AuthContentBox, Input, InputType, Spin } from '../components'
import { emailPattern, passwordPattern } from './patterns'
import { ReactComponent as AccountIcon } from '../icons/account.svg'
import { ReactComponent as LockIcon } from '../icons/lock.svg'
import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { useLogIn } from './useLogIn'
import { ButtonTypes } from '../components/Button'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../appRoutes'
import { useTranslation } from 'react-i18next'

export const LoginPage = () => {
  const [formValues, setFormValues] = useState({ email: '', password: '' })
  const logIn = useLogIn(formValues)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      setLoading(true)
      logIn().finally(() => {
        setLoading(false)
      })
    },
    [logIn]
  )

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setFormValues({ ...formValues, [event.target.name]: event.target.value }),
    [formValues]
  )

  return (
    <AuthContentBox title={t('provideLoginCredentials')}>
      <form className="w-[360px]" onSubmit={handleSubmit}>
        <Input
          icon={<AccountIcon />}
          type={InputType.email}
          label={t('emailAddress')}
          name="email"
          required
          pattern={emailPattern}
          value={formValues.email}
          onChange={handleOnChange}
        />
        <Input
          icon={<LockIcon />}
          type={InputType.password}
          label={t('password')}
          name="password"
          required
          pattern={passwordPattern}
          value={formValues.password}
          onChange={handleOnChange}
          bottomComponent={
            <Link to={AppRoutes.forgotPassword} className="text-xs text-grey -mt-7">
              {t('forgotPassword')}
            </Link>
          }
          error={`${t(
            'pleaseEnterCorrect'
          )} password: 12-32 characters, at least 1 uppercase and lowercase letters, number and special character`}
        />
        {loading ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : (
          <div className="flex justify-end gap-4">
            <Button
              buttonType={ButtonTypes.secondary}
              title={t('createNewAccount')}
              type="button"
              to={AppRoutes.newAccount}
            />
            <Button
              buttonType={ButtonTypes.primary}
              title={t('login')}
              type="submit"
              disabled={Object.values(formValues).some((value) => !value)}
            />
          </div>
        )}
      </form>
    </AuthContentBox>
  )
}
