import { useTranslation } from 'react-i18next'
import { Button, ButtonTypes } from '.'
import { AuthContentBox } from './auth-content-box'
import { Popup } from './popup'

export const DeleteConfirmation = ({
  close,
  onConfirm,
  title,
}: {
  close: () => void
  onConfirm: () => void
  title: string
}) => {
  const { t } = useTranslation()
  return (
    <Popup>
      <AuthContentBox title={title} padding="p-8 pt-2" noLogo>
        <div className="flex justify-end w-full">
          <Button buttonType={ButtonTypes.primary} title={t('yesDelete')} styles={'mr-5'} onClick={onConfirm} />
          <Button buttonType={ButtonTypes.secondary} title={t('cancel')} onClick={close} />
        </div>
      </AuthContentBox>
    </Popup>
  )
}
