import { useContext } from 'react'
import { apiUrls } from '../api/api-urls'
import { fetchMutation, handleError } from '../utils'
import { User } from './fetchUsers'
import { UsersContext } from './users-context'

type UserType = Partial<User>

export const useCreateUser = () => {
  const { users, setUsers } = useContext(UsersContext)
  return (user: UserType) =>
    fetchMutation<User>(apiUrls.users, {
      method: 'PUT',
      body: JSON.stringify(user),
      headers: { 'Content-Type': 'application/json' },
    }).then((data) => {
      if (data?.data) {
        const user = new User(data.data)
        setUsers([...users, user])
      }
    }, handleError)
}
