import { socialMediaColors, socialMediaIcons } from '../components'
import { StatisticsSegment } from '../sme'
import { BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'
import { useIsMobile } from '../utils'

export type CompareData = {
  icon: ReactNode
  header: string
  company: string
  competitor: string
  companyStatistics: {
    company: string
    value: number | undefined
  }[]
  competitorStatistics: {
    company: string
    value: number | undefined
  }[]
}

export const CompareChart = ({ data }: { data: CompareData }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const CategoryTick = (props: any) => {
    return (
      <foreignObject x={props.right ? props.x - 11 : props.x - 40} y={props.y - 20} width={40} height={40}>
        {socialMediaIcons()[props.payload.value]}
      </foreignObject>
    )
  }

  const companyPlatforms = data.companyStatistics.map(({ company }) => company)
  const competitorPlatforms = data.competitorStatistics?.map(({ company }) => company)
  const companyStatistics = data.companyStatistics.filter(({ company }) => competitorPlatforms?.includes(company))
  const competitorStatistics = data.competitorStatistics?.filter(({ company }) => companyPlatforms.includes(company))
  const noCompanyData =
    (companyStatistics as { company: string; value: number }[])?.every(({ value }) => typeof value === 'string') ||
    !companyStatistics
  const noCompetitorData =
    (competitorStatistics as { company: string; value: number }[])?.every(({ value }) => typeof value === 'string') ||
    !competitorStatistics
  const noData = noCompanyData && noCompetitorData
  const height = companyStatistics.length * 60

  const highestValue = Math.max(
    ...([...companyStatistics, ...competitorStatistics]
      .filter(({ value }) => value && typeof value === 'number')
      .map(({ value }) => value) as number[])
  )

  const NoDataComponent = ({ right }: { right?: boolean }) => {
    const className = right
      ? 'flex flex-col gap-[2.1rem] items-start ml-2 mt-6'
      : 'flex flex-col gap-[2.1rem] items-end mr-2 mt-6'
    return (
      <div className={className}>
        {Array(5)
          .fill('')
          .map((_, index) => (
            <div key={index}>{t('noOrWrongId')}</div>
          ))}
      </div>
    )
  }

  const CustomLabel = (props: any) => {
    const { x, y, value, width } = props
    const xLeft = x || 513
    const className = () => {
      let classes = []
      if (props.right) classes.push('text-left')
      if (!props.right) classes.push('text-right')
      if (noCompetitorData && !props.right) classes.push('mr-[70px]')
      if (!noCompetitorData && !props.right) classes.push('mr-3')
      return classes.join(' ')
    }
    return typeof value === 'number' ? (
      <text x={xLeft} y={y} dx={width / 2 - 5} dy={25} fill="#fff" fontSize={22} textAnchor="middle">
        {value}
      </text>
    ) : (
      <foreignObject x={props.right ? 65 : 0} y={y + 5} width="100%" height={30}>
        <div className={className()}>{value}</div>
      </foreignObject>
    )
  }

  const mobileData = data.companyStatistics.map((statistics) => ({
    company: statistics.company,
    companyValue: statistics.value,
    competitorValue: data.competitorStatistics.find(
      (competitorStatistics) => competitorStatistics.company === statistics.company
    )?.value,
  }))

  const MobileCategoryTick = (props: any) => {
    return (
      <foreignObject x={props.x - 36} y={props.y - 20} width={40} height={40}>
        {socialMediaIcons()[props.payload.value]}
      </foreignObject>
    )
  }

  const MobileCustomLabel = (props: any) => {
    const { x, value, width, small } = props
    const y = small ? props.y - 10 : props.y - 6
    return typeof value === 'number' ? (
      <text x={x} y={y} dx={width / 2 - 5} dy={25} fill="#fff" fontSize={isMobile ? 16 : 22} textAnchor="middle">
        {value}
      </text>
    ) : small ? null : (
      <text x={70} y={y} dy={25} fill="#000" fontSize={16}>
        {value}
      </text>
    )
  }

  return (
    <div className="md:px-4">
      <StatisticsSegment icon={data.icon} header={`${data.header} ${noData ? t('noData') : ''}`}>
        {!noData && (
          <>
            {isMobile ? (
              <div className="ml-1">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={mobileData} layout="vertical">
                    <XAxis hide axisLine={false} type="number" />
                    <YAxis
                      yAxisId={0}
                      dataKey={'company'}
                      type="category"
                      axisLine={false}
                      tickLine={false}
                      width={65}
                      padding={{ bottom: 16 }}
                      tickMargin={24}
                      tick={MobileCategoryTick}
                    />
                    <Bar
                      dataKey={'companyValue'}
                      minPointSize={String(highestValue).length * 14}
                      barSize={24}
                      radius={20}
                      label={<MobileCustomLabel />}
                    >
                      {mobileData.map(({ company }) => {
                        return <Cell key={company} fill={socialMediaColors[company]} />
                      })}
                    </Bar>
                    <Bar
                      dataKey={'competitorValue'}
                      minPointSize={String(highestValue).length * 14}
                      barSize={16}
                      radius={20}
                      label={<MobileCustomLabel small />}
                      fill="#948F93"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <>
                <div className="flex gap-10">
                  <p className="text-2xl text-primary w-[46%] text-right pr-0">{data.company}</p>
                  <p className="text-2xl text-secondary w-[54%] text-left pl-7">{data.competitor}</p>
                </div>
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                  <ResponsiveContainer width={noCompetitorData ? '54%' : '45%'} height={height}>
                    {noCompanyData ? (
                      <NoDataComponent />
                    ) : (
                      <BarChart width={100} height={40} data={companyStatistics} layout="vertical">
                        <XAxis hide axisLine={false} type="number" reversed />
                        <YAxis yAxisId={0} dataKey={'company'} type="category" hide />
                        {noCompetitorData ? (
                          <YAxis
                            yAxisId={1}
                            dataKey={'company'}
                            type="category"
                            axisLine={false}
                            tickLine={false}
                            width={61}
                            tick={(props) => <CategoryTick {...props} right />}
                            orientation="right"
                            tickMargin={20}
                          />
                        ) : null}
                        <Bar
                          dataKey={'value'}
                          minPointSize={String(highestValue).length * 14}
                          barSize={36}
                          radius={20}
                          label={<CustomLabel />}
                        >
                          {companyStatistics.map(({ company }: { company: string }) => {
                            return <Cell key={company} fill={socialMediaColors[company]} />
                          })}
                        </Bar>
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                  <ResponsiveContainer width={'54%'} height={height}>
                    {noCompetitorData ? (
                      <NoDataComponent right />
                    ) : (
                      <BarChart data={competitorStatistics} layout="vertical">
                        <XAxis hide axisLine={false} type="number" />
                        <YAxis
                          yAxisId={0}
                          dataKey={'company'}
                          type="category"
                          axisLine={false}
                          tickLine={false}
                          width={50}
                          tick={CategoryTick}
                        />
                        <Bar
                          dataKey={'value'}
                          minPointSize={String(highestValue).length * 14}
                          barSize={36}
                          radius={20}
                          label={<CustomLabel right />}
                        >
                          {competitorStatistics?.map(({ company }: { company: string }) => {
                            return <Cell key={company} fill={socialMediaColors[company]} />
                          })}
                        </Bar>
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                  {/* TODO: How to calculate that values? */}
                  {/* <div style={{ width: '110px', height: '100%', marginLeft: 50 }}> */}
                  {/* <ResponsiveContainer width="100%" height={height}>
                  <BarChart data={diffData} layout="vertical">
                    <XAxis hide axisLine={false} type="number" reversed />
                    <YAxis yAxisId={0} dataKey={'company'} type="category" hide />
                    <Bar
                      dataKey={'diff'}
                      minPointSize={100}
                      barSize={36}
                      radius={20}
                      label={{
                        fill: '#188416',
                        formatter: (value: number) => {
                          return `${(value * 100).toFixed()}%`
                        },
                      }}
                    >
                      {diffData.map(({ company, diff }: { company: string; diff: number }) => {
                        const diffValues = diffData.map((data) => data.diff)
                        return <Cell key={company} fill={diff === Math.min(...diffValues) ? '#FFDADB' : '#DBFFDA'} />
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer> */}
                  {/* </div> */}
                </div>
              </>
            )}
          </>
        )}
      </StatisticsSegment>
    </div>
  )
}
