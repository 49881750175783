import { useEffect, useRef, useState } from 'react'
import { useOutsideClick } from '../utils'
import { Input, InputType } from './input'
import { ReactComponent as GlobeIcon } from '../icons/globe.svg'
import { SocialMediaPlatforms } from '../sme/sme-context'
import { useTranslation } from 'react-i18next'
import { Spin } from './spin'
import { getCompanySuggestions } from '../sme/getCompanySuggestions'
import debounce from 'lodash.debounce'

export const AutocompleteInput = ({
  selectedValue,
  onSelect,
  platform,
  name,
}: {
  selectedValue: { label: string; value: string }
  onSelect: (value: { label: string; value: string }) => void
  platform: SocialMediaPlatforms
  name: string
}) => {
  const [value, setValue] = useState(selectedValue.label)
  const [isDropped, setIsDropped] = useState(false)
  const clickOutside = useOutsideClick(() => setIsDropped(false))
  const [options, setOptions] = useState<{ label: string; value: string }[]>([])
  const [loading, setLoading] = useState(false)
  const [channelId, setChannelId] = useState<string>(selectedValue.value)
  const [error, setError] = useState<Error | undefined>(undefined)
  const { t } = useTranslation()

  const getSuggestions = useRef(
    debounce((value: string) => {
      setLoading(true)
      return getCompanySuggestions(platform, value)
        .then(
          (result) =>
            setOptions(
              result?.data.map((data) => ({
                label: data.name,
                value: data.id,
              })) || []
            ),
          setError
        )
        .finally(() => {
          setLoading(false)
        })
    }, 500)
  ).current

  useEffect(() => {
    if (channelId) {
      onSelect({ label: value, value: channelId })
    }
  }, [channelId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="relative">
      <input name={name} hidden value={channelId} onChange={() => {}} />
      <Input
        icon={<GlobeIcon />}
        label={t(platform)}
        type={InputType.text}
        name={`${name}-label`}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
          setChannelId(event.target.value)
          setIsDropped(true)
          if (event.target.value) {
            getSuggestions(event.target.value)
          }
        }}
        onClick={() => setIsDropped(true)}
        labelBg="before:bg-[#E9E1ED]"
        placeholder={t(platform)}
        data-dropdown-toggle="dropdownDivider"
        autoComplete="off"
      />
      {isDropped && value && (
        <div
          ref={clickOutside}
          id="dropdownDivider"
          className="absolute top-[65px] w-full z-[12] rounded-[16px] overflow-hidden shadow-dialog"
        >
          {loading ? (
            <div className="bg-white w-full min-h-[35px] flex items-center justify-center p-[10px]">
              <Spin />
            </div>
          ) : options.length > 0 ? (
            <ul aria-labelledby="dropdownDividerButton">
              {options.map((value, index) => {
                const select = () => {
                  setValue(value.label)
                  setChannelId(value.value)
                  setIsDropped(false)
                }

                return (
                  <li key={index}>
                    <button
                      onClick={select}
                      className="bg-white hover:bg-ghostWhite w-full min-h-[35px] items-center p-[10px]"
                    >
                      <p className="text-dropDownText text-[14px] m-w-[75px] text-left">{value.label}</p>
                    </button>
                  </li>
                )
              })}
            </ul>
          ) : error && 'message' in error ? (
            <div className="bg-white w-full min-h-[35px] flex items-center justify-center p-[10px] text-error">
              <p>{error.message.replace('Error: ', '')}</p>
            </div>
          ) : (
            <div className="bg-white w-full min-h-[35px] flex items-center justify-center p-[10px]">
              <p>{t('noSuggestions')}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
