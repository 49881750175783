import { InputHTMLAttributes, ReactNode } from 'react'
import { ReactComponent as WarnIcon } from '../icons/warn.svg'
import { useTranslation } from 'react-i18next'

export enum InputType {
  text = 'text',
  email = 'email',
  password = 'password',
  url = 'url',
  date = 'datetime-local',
}

export const Input = ({
  icon,
  type = InputType.text,
  placeholder,
  label,
  labelBg = 'before:bg-ghostWhite',
  name,
  bottomInfo,
  bottomComponent,
  error,
  ...props
}: {
  icon?: ReactNode
  bottomComponent?: ReactNode
  type?: InputType
  placeholder?: string
  bottomInfo?: string
  label?: string
  labelBg?: string
  name: string
  error?: string
} & InputHTMLAttributes<HTMLInputElement>) => {
  const { t } = useTranslation()
  const inputPlaceholder =
    type === 'password' ? 'Enter password' : type === 'email' ? 'john.doe@gmail.com' : placeholder
  const inputPaddingLeft = icon ? 'pl-12' : 'pl-4'
  return (
    <>
      <div className="relative max-w-[410px] w-full mx-auto">
        <input
          type={type}
          className={`block rounded p-4 ${inputPaddingLeft} w-full text-base text-gray-900 bg-transparent border-2 border-primary appearance-none focus:outline-none focus:shadow-input focus:ring-0 mb-8 mt-4 placeholder-[#4B454D] autofill:!bg-ghostWhite peer [&:not(:placeholder-shown):invalid]:border-error [&:not(:placeholder-shown):invalid]:shadow-inputError`}
          placeholder={placeholder || inputPlaceholder}
          name={name}
          {...props}
        />
        {icon && (
          <div className="absolute top-4 left-0 pl-4 pointer-events-none peer-[&:not(:placeholder-shown):invalid]:text-error">
            {icon}
          </div>
        )}
        {label && (
          <label
            className={`absolute text-xs text-primary -top-2 z-10 left-4 before:content-[''] before:absolute before:inset-x-0 before:h-[5px] ${labelBg} before:z-[-1] before:top-1/2 before:-translate-y-2/4 px-2 peer-[&:not(:placeholder-shown):invalid]:text-error`}
          >
            {label}
          </label>
        )}
        {error ? (
          type === InputType.date ? (
            <>
              <WarnIcon className="absolute top-4 right-0 pr-4 pointer-events-none w-[40px] peer-[&:not(:placeholder-shown):invalid]:block " />
              <div className="-mt-6 text-sm text-red-500 peer-[&:not(:placeholder-shown):invalid]:block">{error}</div>
              <span className="mt-2 text-xs text-grey peer-[&:not(:placeholder-shown):invalid]:hidden absolute -bottom-5 left-4">
                {bottomInfo || ''}
                {bottomComponent && bottomComponent}
              </span>
            </>
          ) : (
            <>
              <WarnIcon className="absolute hidden top-4 right-0 pr-4 pointer-events-none w-[40px] peer-[&:not(:placeholder-shown):invalid]:block " />
              <div className="-mt-6 text-sm hidden text-red-500 peer-[&:not(:placeholder-shown):invalid]:block">
                {error}
              </div>
              <span className="mt-2 text-xs text-grey peer-[&:not(:placeholder-shown):invalid]:hidden absolute -bottom-5 left-4">
                {bottomInfo || ''}
                {bottomComponent && bottomComponent}
              </span>
            </>
          )
        ) : (
          <>
            <WarnIcon className="hidden peer-[&:not(:placeholder-shown):invalid]:block absolute top-4 right-0 pr-4 pointer-events-none w-[40px]" />
            <span className="mt-2 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):invalid]:block absolute -bottom-5 right-5">
              {t('pleaseEnterCorrect', { value: label?.toLowerCase() || 'value' })}
            </span>
            <span className="mt-2 text-xs text-grey peer-[&:not(:placeholder-shown):invalid]:hidden absolute -bottom-5 left-4">
              {bottomInfo || ''}
              {bottomComponent && bottomComponent}
            </span>
          </>
        )}
      </div>
    </>
  )
}
