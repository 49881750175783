import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import defaultEn from './locales/en/default.json'
import defaultFr from './locales/fr/default.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: defaultEn,
    },
    fr: {
      translation: defaultFr,
    },
  },
  lng: window.localStorage.getItem('language') || navigator.language || 'en',
  fallbackLng: 'en',
  debug: false,
  returnEmptyString: false,
  react: {
    useSuspense: false,
  },
})

export default i18n
