import { useState } from 'react'
import { ReactComponent as DropdownIcon } from '../icons/dropdownIcon.svg'
import { useOutsideClick } from '../utils'

export type Period = 'day' | 'week' | 'month' | 'year'

export const Dropdown = ({
  selectedValue,
  values,
  onSelect,
}: {
  selectedValue: Period
  values: Period[]
  onSelect: (value: Period) => void
}) => {
  const [valueSelected, setSelectedValue] = useState<Period>(selectedValue)
  const [isDropped, setIsDropped] = useState(false)
  const clickOutside = useOutsideClick(() => setIsDropped(false))

  return (
    <div>
      <button
        onClick={() => {
          setIsDropped((dropped) => !dropped)
        }}
        id="dropdownDividerButton"
        data-dropdown-toggle="dropdownDivider"
        className="bg-white min-w-[90px] min-h-[35px] rounded-[16px] flex flex-row items-center justify-between p-[10px]"
      >
        <p className="text-dropDownText text-[14px] m-w-[75px]">{valueSelected}</p>
        <DropdownIcon />
      </button>
      {isDropped && (
        <div
          ref={clickOutside}
          id="dropdownDivider"
          className=" absolute mt-[10px] z-10 rounded-[16px] overflow-hidden shadow-dialog"
        >
          <ul aria-labelledby="dropdownDividerButton">
            {values.map((value) => {
              const select = () => {
                setSelectedValue(value)
                onSelect(value)
                setIsDropped(false)
              }

              return (
                <li key={value}>
                  <button
                    onClick={select}
                    className="bg-white hover:bg-ghostWhite min-w-[90px] min-h-[35px] items-center p-[10px]"
                  >
                    <p className="text-dropDownText text-[14px] m-w-[75px] text-left">{value}</p>
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}
