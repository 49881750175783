import { AppRoutes } from '../appRoutes'
import { Button, ButtonTypes, ContentBox, Heading, HorizontalChart, PieChart } from '../components'
import { ReactComponent as GlobeIcon } from '../icons/globe.svg'
import { ReactComponent as ContentIcon } from '../icons/content.svg'
import { ReactComponent as EyeIcon } from '../icons/eye.svg'
import { ReactComponent as StarIcon } from '../icons/star.svg'
import { ReactComponent as AccountIcon } from '../icons/account.svg'
import { ReactComponent as StickerIcon } from '../icons/sticker.svg'
import { StatisticsSegment } from './statistics-segment'
import { SMESummaryItem } from './sme-summary-item'
import { RecommendationButton } from './recommendation-button'
import { TopKeywords } from './top-keywords'
import { Navigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { SME, SMEContext } from './sme-context'
import { Period } from '../components/dropdown'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '../utils'

export const SMEPage = () => {
  const { data, company } = useContext(SMEContext)
  const [selectedPeriod, setSelectedPeriod] = useState<Period>('month')
  const { t } = useTranslation()
  const isMobile = useIsMobile(767)

  if (!data || Object.values(data).every((value) => !value)) {
    return <Navigate to={AppRoutes.home} />
  }

  if (data && Object.values(data).some((value) => value?.error)) {
    alert(Object.values(data).find((value) => value?.error)?.error?.message)
    return <Navigate to={AppRoutes.home} />
  }

  const preparedData =
    data &&
    ['instagram', 'twitter', 'youtube', 'tiktok', 'linkedin'].map((value) => ({
      company: value,
      value: data[value as keyof SME],
    }))

  const toNumber = (value: unknown) => {
    const numberValue = Number(value)
    if (numberValue || numberValue === 0) return numberValue
    return t('noOrWrongId')
  }

  const firstColumnData = [
    {
      icon: <GlobeIcon />,
      header: t('whereDoYouPost'),
      statistics: preparedData?.map(({ company, value }) => ({
        company,
        value: toNumber(value?.statistic?.postCount),
      })),
    },
    {
      icon: <ContentIcon />,
      header: t('howOftenPostPer'),
      statistics: preparedData?.map(({ company, value }) => ({
        company,
        value: toNumber(value?.periods?.[selectedPeriod].postCount),
      })),
      withSelect: true,
    },
    {
      icon: <EyeIcon />,
      header: t('numberOfViews'),
      statistics: preparedData?.map(({ company, value }) => ({
        company,
        value: toNumber(value?.statistic?.viewCount),
      })),
    },
    {
      icon: <StarIcon />,
      header: t('topUserKeywords'),
      statistics: preparedData?.map(({ company, value }) => ({
        company,
        value: value?.keywords,
      })),
    },
  ]

  const secondColumnData = [
    {
      icon: <AccountIcon />,
      header: t('howManySubscribersYouHave'),
      statistics: preparedData?.map(({ company, value }) => {
        return {
          company,
          value:
            Number(value?.statistic?.subscriberCount) === 0
              ? Number(value?.statistic?.subscriberCount)
              : Number(value?.statistic?.subscriberCount) || '-',
        }
      }),
    },
    {
      icon: <StickerIcon />,
      header: t('summaryOfYourActivity'),
      statistics: preparedData?.map(({ company, value }) => ({
        company,
        value: value?.summary || t('noOrWrongId'),
      })),
    },
  ]

  if (isMobile) {
    firstColumnData.unshift(secondColumnData[0])
    secondColumnData.shift()
  }

  return (
    <div className="flex flex-col w-full">
      <ContentBox>
        <div className="flex gap-2 md:gap-6 items-start items-end mb-2 md:mb-4 w-full flex-wrap">
          <Heading>{company}</Heading>
          <p className="text-base md:text-[1.75rem] leading-[2.1rem] md:leading-9 text-secondary font-medium">
            {t('socialMediaEcosystem')}
          </p>
        </div>
        <p className="max-w-[500px] mb-10 font-medium text-darkPurple text-sm md:text-base">
          {t('socialMediaEcosystemFor', { company })}
        </p>

        <div className="flex flex-col md:flex-row gap-8 w-full md:px-2 md:px-10">
          <div className="flex flex-col basis-1/2 gap-10 max-w-[570px]">
            {firstColumnData.map(({ icon, header, statistics, withSelect }, index) => (
              <StatisticsSegment
                handleSelect={setSelectedPeriod}
                selectedValue={selectedPeriod}
                key={index}
                icon={icon}
                header={header}
                withSelect={withSelect}
              >
                {header === t('topUserKeywords') ? (
                  <TopKeywords data={statistics as { company: string; value: string[] }[]} />
                ) : statistics &&
                  (statistics as { company: string; value: string | number | undefined }[]).every(
                    (statistic) => typeof statistic.value === 'string'
                  ) ? (
                  t('noData')
                ) : header === t('howManySubscribersYouHave') ? (
                  <PieChart data={statistics as unknown as { company: string; value: number }[]} units="subs" />
                ) : (
                  <HorizontalChart data={statistics as { company: string; value: number }[]} />
                )}
              </StatisticsSegment>
            ))}
          </div>
          <div className="flex flex-col basis-1/2 gap-10">
            {secondColumnData.map(({ icon, header, statistics }, index) => (
              <StatisticsSegment key={index} icon={icon} header={header}>
                {header === t('summaryOfYourActivity') ? (
                  statistics?.map((data, index) => <SMESummaryItem key={index} data={data} />)
                ) : statistics &&
                  (statistics as { company: string; value: string | number | undefined }[]).every(
                    (statistic) => !statistic.value
                  ) ? (
                  t('noData')
                ) : (
                  <PieChart data={statistics as unknown as { company: string; value: number }[]} units="subs" />
                )}
              </StatisticsSegment>
            ))}
          </div>
        </div>
        <RecommendationButton />
      </ContentBox>
      <Button
        buttonType={ButtonTypes.secondary}
        title={t('compareWithCompetitors')}
        styles="flex justify-start md:justify-center mt-10"
        to={AppRoutes.compare}
      />
    </div>
  )
}
