import { useContext } from 'react'
import { UsersContext } from '.'
import { apiUrls } from '../api/api-urls'
import { fetchMutation } from '../utils'
import { handleError } from '../utils/handleError'
import { User } from './fetchUsers'

type UserType = Partial<User>

export const useUpdateUser = () => {
  const { users, setUsers } = useContext(UsersContext)
  return (user: UserType) =>
    fetchMutation<User>(`${apiUrls.users}/${user.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        start_date: Number(user.start_date),
        end_date: Number(user.end_date),
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((data) => {
      if (data?.data) {
        const newUser = new User(data.data)
        const updatedUsers = users.map((user) => (user.id === newUser.id ? { ...user, ...newUser } : user))
        setUsers(updatedUsers)
      }
    }, handleError)
}
