import { ButtonHTMLAttributes, ReactNode } from 'react'
import { Link } from 'react-router-dom'

export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
}

type ButtonProps = {
  title: string
  styles?: string
  to?: string
  icon?: ReactNode
  buttonType?: ButtonTypes
  onClick?: () => void
} & ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({
  title,
  to,
  icon,
  buttonType = ButtonTypes.secondary,
  styles,
  onClick,
  ...props
}: ButtonProps) => {
  const children = (
    <>
      {icon && <div className={classNames.iconButton}>{icon}</div>}
      {title}
    </>
  )
  return (
    <div className={styles}>
      {to ? (
        <Link to={to} className={typeStyles[buttonType]}>
          {children}
        </Link>
      ) : (
        <button onClick={onClick} className={typeStyles[buttonType]} {...props}>
          {children}
        </button>
      )}
    </div>
  )
}

const classNames = {
  button: 'flex items-center justify-center px-6 py-2.5 rounded-full text-sm w-[inherit] font-medium',
  iconButton: 'w-buttonIcon h-buttonIcon mr-2',
}

const typeStyles = {
  primary: `${classNames.button} text-white bg-primary hover:shadow-button disabled:shadow-[none] hover:bg-hoverPrimary hover:scale-sc-button-hover disabled:scale-[none] disabled:bg-secondary`,
  secondary: `${classNames.button} text-black bg-pink hover:shadow-button disabled:shadow-[none] hover:bg-hoverPink hover:scale-sc-button-hover disabled:scale-[none]`,
}
