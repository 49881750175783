export enum AppRoutes {
  home = '/',
  login = '/login',
  passwordReset = '/reset-password',
  forgotPassword = '/forgot-password',
  changePassword = '/change-password',
  newAccount = '/new-account',
  sme = '/sme',
  compare = '/compare',
  recommendation = '/recommendation',
  users = '/users',
  privacy = '/privacy',
}
