import './setupI18n'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './auth/auth-context'
import { UsersProvider } from './users'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <AuthProvider>
      <UsersProvider>
        <Router>
          <App />
        </Router>
      </UsersProvider>
    </AuthProvider>
  </React.StrictMode>
)
