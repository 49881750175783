import { useMemo } from 'react'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { Outcomes } from './recommendation-page'
import { useTranslation } from 'react-i18next'

export const RecommendationChart = ({ data }: { data: Outcomes }) => {
  const { t } = useTranslation()
  const dataChart = useMemo(
    () => [
      {
        name: t('numberOfSubscribers'),
        number: data.subscribers,
      },
      {
        name: t('numberOfLikes'),
        number: data.likes,
      },
      {
        name: t('numberOfViews'),
        number: data.views,
      },
    ],
    [data, t]
  )

  const CategoryTick = (props: any) => {
    return (
      <foreignObject x={props.x - 165} y={props.y - 10} width={170} height={40}>
        <p className="text-darkPurple text-sm">{dataChart[props.payload.index].name}</p>
      </foreignObject>
    )
  }

  return (
    <div style={{ width: '100%', height: '100%', marginLeft: -30 }}>
      <ResponsiveContainer height={140} width="100%">
        <BarChart data={dataChart} layout="vertical">
          <XAxis interval={0} hide axisLine={false} type="number" />
          <YAxis
            dataKey={'number'}
            type="category"
            axisLine={false}
            tickLine={false}
            width={200}
            interval={0}
            tick={CategoryTick}
          />
          <Bar
            stackId={'a'}
            dataKey={'number'}
            minPointSize={50}
            barSize={36}
            radius={20}
            label={{ fill: 'white', fontSize: 22 }}
            fill="#F15BB5"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
