import { ReactNode, createContext, useState } from 'react'

export type Period = {
  viewCount: number
  likeCount: number
  favoriteCount: number
  commentCount: number
  postCount: number
}

export type Report = {
  keywords?: string[]
  statistic?: Record<string, string | boolean>
  periods?: {
    day: Period
    week: Period
    month: Period
    year: Period
  }
  summary?: string
  error?: {
    message: string
  }
}

export type SME = {
  instagram?: Report
  tiktok?: Report
  twitter?: Report
  youtube?: Report
  facebook?: Report
  linkedin?: Report
}

export enum SocialMediaPlatforms {
  ig = 'ig',
  tiktok = 'tiktok',
  twitter = 'twitter',
  youtube = 'youtube',
  linkedin = 'linkedin',
}

export const socialMedia = [
  SocialMediaPlatforms.ig,
  SocialMediaPlatforms.tiktok,
  SocialMediaPlatforms.twitter,
  SocialMediaPlatforms.youtube,
  SocialMediaPlatforms.linkedin,
]

export const SMEContext = createContext<{
  company: string
  setCompany: (value: string) => void
  data: SME | undefined
  setData: (data: SME | undefined) => void
  competitor1Data: SME | undefined
  setCompetitor1Data: (data: SME | undefined) => void
  competitor2Data: SME | undefined
  setCompetitor2Data: (data: SME | undefined) => void
  competitor3Data: SME | undefined
  setCompetitor3Data: (data: SME | undefined) => void
  competitorCompany1: { label: string; value: string }
  setCompetitorCompany1: (value: { label: string; value: string }) => void
  competitorCompany2: { label: string; value: string }
  setCompetitorCompany2: (value: { label: string; value: string }) => void
  competitorCompany3: { label: string; value: string }
  setCompetitorCompany3: (value: { label: string; value: string }) => void
}>({
  company: '',
  setCompany: () => {},
  data: undefined,
  setData: () => {},
  competitor1Data: undefined,
  setCompetitor1Data: () => {},
  competitor2Data: undefined,
  setCompetitor2Data: () => {},
  competitor3Data: undefined,
  setCompetitor3Data: () => {},
  competitorCompany1: { label: '', value: '' },
  setCompetitorCompany1: () => {},
  competitorCompany2: { label: '', value: '' },
  setCompetitorCompany2: () => {},
  competitorCompany3: { label: '', value: '' },
  setCompetitorCompany3: () => {},
})

export const SMEProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<SME | undefined>(undefined)
  const [competitor1Data, setCompetitor1Data] = useState<SME | undefined>(undefined)
  const [competitor2Data, setCompetitor2Data] = useState<SME | undefined>(undefined)
  const [competitor3Data, setCompetitor3Data] = useState<SME | undefined>(undefined)
  const [company, setCompany] = useState<string>('')
  const [competitorCompany1, setCompetitorCompany1] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  })
  const [competitorCompany2, setCompetitorCompany2] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  })
  const [competitorCompany3, setCompetitorCompany3] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  })

  return (
    <SMEContext.Provider
      value={{
        company,
        data,
        setData,
        setCompany,
        competitor1Data,
        setCompetitor1Data,
        competitor2Data,
        setCompetitor2Data,
        competitor3Data,
        setCompetitor3Data,
        competitorCompany1,
        setCompetitorCompany1,
        competitorCompany2,
        setCompetitorCompany2,
        competitorCompany3,
        setCompetitorCompany3,
      }}
    >
      {children}
    </SMEContext.Provider>
  )
}
