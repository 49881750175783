import { useContext } from 'react'
import { apiUrls } from '../api/api-urls'
import { fetchMutation, getInfoFromAuthToken, handleError } from '../utils'
import { AuthContext } from './auth-context'

export const useLogIn = (values: { email: string; password: string }) => {
  const { setAuthentication } = useContext(AuthContext)
  return () =>
    fetchMutation<{ token: string }>(apiUrls.login, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => {
      const token = response?.data.token
      if (token) {
        const { role } = getInfoFromAuthToken(token)
        setAuthentication({ token, email: values.email, role })
      }
    }, handleError)
}
