import { ReactNode, createContext, useState } from 'react'

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type Authentication = {
  token: string
  email: string
  role: Role
}

export enum AuthenticationKey {
  token = 'token',
  email = 'email',
  role = 'role',
}

export const AuthContext = createContext<{
  authentication: Authentication | undefined
  setAuthentication: (authentication: Authentication) => void
}>({
  authentication: undefined,
  setAuthentication: () => {},
})

const persistAuthentication = (authentication?: Authentication) => {
  if (authentication) {
    window.localStorage.setItem(AuthenticationKey.token, authentication.token)
    window.localStorage.setItem(AuthenticationKey.email, authentication.email)
    window.localStorage.setItem(AuthenticationKey.role, authentication.role)
  } else {
    window.localStorage.removeItem(AuthenticationKey.token)
    window.localStorage.removeItem(AuthenticationKey.email)
    window.localStorage.removeItem(AuthenticationKey.role)
  }
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authentication, storeAuthentication] = useState(() => {
    try {
      const token = window.localStorage.getItem(AuthenticationKey.token)
      const email = window.localStorage.getItem(AuthenticationKey.email)
      const role = window.localStorage.getItem(AuthenticationKey.role)

      return token && email && role ? ({ token, email, role } as Authentication) : undefined
    } catch (error) {
      console.error(error)
      return undefined
    }
  })

  const setAuthentication = (authentication: Authentication) => {
    try {
      storeAuthentication(authentication)
      persistAuthentication(authentication)
    } catch (error) {
      console.error(error)
    }
  }

  return <AuthContext.Provider value={{ authentication, setAuthentication }}>{children}</AuthContext.Provider>
}
