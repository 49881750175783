import { Button, AuthContentBox, Input, InputType } from '../components'
import { handleError } from '../utils'
import { passwordPattern } from './patterns'
import { ReactComponent as LockIcon } from '../icons/lock.svg'
import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { ButtonTypes } from '../components/Button'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { getInfoFromAuthToken } from '../utils/getInfoFromAuthToken'

import { AppRoutes } from '../appRoutes'
import { postResetPassword } from './postResetPassword'
import { useTranslation } from 'react-i18next'

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams()
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: '',
  })
  const [isSending, setIsSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const isSamePassword = formValues.confirmPassword === formValues.password
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goToLogin = useCallback(() => navigate(AppRoutes.login), [navigate])

  const token = searchParams.get('token') || ''
  const { email } = getInfoFromAuthToken(token)

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      setIsSending(true)
      postResetPassword({ email, password: formValues.password, token })
        .then(() => {
          setSuccess(true)
        }, handleError)
        .finally(() => setIsSending(false))
    },
    [email, formValues.password, token]
  )

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setFormValues({ ...formValues, [event.target.name]: event.target.value }),
    [formValues]
  )

  if (!token) {
    return <Navigate to={AppRoutes.home} />
  }

  return (
    <AuthContentBox title={success ? t('passwordSuccessfullyReset') : t('changePassword')}>
      {success ? (
        <>
          <p className="text-xs font-medium mb-8 text-primary">{t('confirmationPasswordChangeSent')}</p>
          <Button buttonType={ButtonTypes.primary} title={t('login')} styles={'w-[340px]'} onClick={goToLogin} />
        </>
      ) : (
        <form className="w-[360px]" onSubmit={handleSubmit}>
          <Input
            icon={<LockIcon />}
            type={InputType.password}
            label={t('newPassword')}
            name="password"
            required
            pattern={passwordPattern}
            value={formValues.password}
            placeholder={t('enterNewPassword')}
            bottomInfo={t('minCharPassword')}
            onChange={handleOnChange}
          />
          <Input
            icon={<LockIcon />}
            type={InputType.password}
            label={t('confirmPassword')}
            name="confirmPassword"
            required
            pattern={passwordPattern}
            value={formValues.confirmPassword}
            placeholder={t('confirmNewPassword')}
            onChange={handleOnChange}
            error={formValues.confirmPassword && !isSamePassword ? t('pleaseEnterSamePassword') : undefined}
          />
          <Button
            buttonType={ButtonTypes.primary}
            title={t('saveChanges')}
            styles="flex justify-center w-full"
            type="submit"
            disabled={Object.values(formValues).some((value) => !value) || !isSamePassword || isSending}
          />
        </form>
      )}
    </AuthContentBox>
  )
}
