import { apiUrls } from '../api/api-urls'
import { fetchMutation } from '../utils'
import { SME } from './sme-context'

export const postReports = (body: {
  ig: string
  tiktok: string
  twitter: string
  youtube: string
  linkedin: string
  withoutSummary?: boolean
}) =>
  fetchMutation<SME>(
    apiUrls.reports,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    },
    true
  )
