import { ReactComponent as EditIcon } from '../../icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../icons/trash.svg'
import { TableProps } from './types'
import { UsersContext } from '../../users'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const MobileItem = ({ title, value }: { title: string; value: string }) => (
  <div className="border-b border-grey text-base text-xs p-4 grid grid-cols-3 gap-10">
    <div className="font-semibold">{title}</div>
    <div className="col-span-2">{value}</div>
  </div>
)

export const TableMobile = ({ users }: TableProps) => {
  const { setSelectedUser } = useContext(UsersContext)
  const { t } = useTranslation()

  return users.length === 0 ? (
    <div className="border-b border-grey text-base text-center text-xs pb-4">{t('noUsers')}</div>
  ) : (
    <div>
      {users.map((user) => (
        <div className="mb-2">
          <MobileItem title={t('firstname')} value={user.firstname} />
          <MobileItem title={t('lastname')} value={user.lastname} />
          <MobileItem title={t('email')} value={user.email} />
          <MobileItem title={t('active')} value={user.expired ? t('expired') : t('active')} />
          <div className="flex gap-4 justify-end mt-3 px-4">
            <EditIcon className="cursor-pointer" onClick={() => setSelectedUser(user)} />
            <DeleteIcon className="cursor-pointer" onClick={() => setSelectedUser({ ...user, toDelete: true })} />
          </div>
        </div>
      ))}
    </div>
  )
}
