import { apiUrls } from '../api/api-urls'
import { fetchMutation } from '../utils'

type ResetPasswordBody = {
  email: string
  token: string
  password: string
}

export const postResetPassword = (body: ResetPasswordBody) =>
  fetchMutation(apiUrls.resetPassword, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
