import { ReactComponent as YouTubeIcon } from '../icons/selectedYoutube.svg'
import { ReactComponent as FacebookIcon } from '../icons/selectedFacebook.svg'
import { ReactComponent as LinkedInIcon } from '../icons/selectedLinkedin.svg'
import { ReactComponent as InstagramIcon } from '../icons/selectedInstagram.svg'
import { ReactComponent as TwitterIcon } from '../icons/selectedTwitter.svg'
import { ReactComponent as TikTokIcon } from '../icons/selectedTikTok.svg'
import { ReactNode } from 'react'

export const socialMediaIcons: (width?: number, height?: number) => Record<string, ReactNode> = (
  width = 40,
  height
) => ({
  youtube: <YouTubeIcon width={width} height={height} />,
  facebook: <FacebookIcon width={width} height={height} />,
  linkedin: <LinkedInIcon width={width} height={height} />,
  instagram: <InstagramIcon width={width} height={height} />,
  twitter: <TwitterIcon width={width} height={height} />,
  tiktok: <TikTokIcon width={width} height={height} />,
})

export const socialMediaColors: Record<string, string> = {
  youtube: '#F15BB5',
  facebook: '#55ACEE',
  linkedin: '#FEE440',
  instagram: '#00F5D4',
  twitter: '#9B5DE5',
  tiktok: '#000',
}
