export const text = {
  policy: 'Privacy policy',
  contactInfo: 'Contact info',
  provideLoginCredentials: 'Please provide login credentials:',
  cancel: 'Cancel',
  login: 'Login',
  forgotPassword: 'Forgot password?',
  reset: 'Reset',
  emailAddress: 'Email address',
  password: 'Password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  confirmNewPassword: 'Confirm new password',
  enterNewPassword: 'Enter new password',
  passwordReset: 'Password reset',
  passwordResetDescription:
    'Please enter your email address that you provided during user registration. If it matches one in our database, the password reset link will be sent.',
  pleaseEnterCorrect: 'Please enter correct',
  saveChanges: 'Save changes',
  changePassword: 'Change password',
  minCharPassword: 'Min. 12 characters.',
  pleaseEnterSamePassword: 'Please enter same password',
  passwordSuccessfullyReset: 'Password successfully reset',
  confirmationPasswordChangeSent: 'Confirmation of password change sent to e-mail address.',
  yesDelete: 'Yes, delete',
  logout: 'Logout',
  loading: 'Loading...',
  waitForEmailWithSetPassword: 'Wait for an email with a link to set your password.',
  createNewAccount: 'Create new account',
  newAccount: 'New account',
  companyUrl: 'Company URL',
  aixplorDescription: `Welcome to AIxplor social media visibility enhancement tool. Based on company's URL we can provide comprehensive view on social media visibility of company of interest.`,
  check: 'Check',
  compareWithCompetitors: 'Compare with competitors',
  socialMediaEcosystem: 'Social Media Ecosystem',
  socialMediaEcosystemFor: 'This is a Social Media Ecosystem for',
  companyDescription: 'Here you can view most important data about your activity in five top social media platforms.',
  whereDoYouPost: 'Where do you post?',
  howOftenPostPer: 'How often do you post per',
  numberOfViews: 'Number of views',
  topUserKeywords: 'Top used Key words',
  howManySubscribersYouHave: 'How many subscribers do you have?',
  summaryOfYourActivity: 'Summary of your activity',
  recommendation: 'recommendation',
  summary: 'Summary',
  expectedOutcomes: 'Expected outcomes',
  detailedRecommendation: 'Detailed recommendation to improve statistics on',
  goBackToSme: 'Go back to SME',
  numberOfSubscribers: 'Number of subscribers:',
  numberOfPosts: 'Number of posts:',
  buttonRecommendation: 'Recommendation',
  compareDescription:
    'By examining these key aspects, we aim to provide you with a comprehensive overview of the similarities, differences, and unique features of',
  allCompetitorsOn: 'All Competitors on',
  inVivo: 'InVivo',
  numberOfLikes: 'Number of likes',
  typeCompetitorUrl: `Please provide competitor company's URL`,
  users: 'Users list',
  listOfUsers: 'List of users',
  add: 'Add',
  addUser: 'Add user',
  sureToDeleteUser: 'Are you sure you want to delete this user?',
  firstname: 'First name',
  lastname: 'Last name',
  pleaseFill: 'Please fill',
  email: 'E-mail',
  actions: 'Actions',
  noUsers: 'No users',
  update: 'Update',
  editUser: 'Edit user',
}
