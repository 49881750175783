import { ReactNode, useContext, useState } from 'react'
import { ReactComponent as Logo } from '../icons/logo.svg'
import { Link } from 'react-router-dom'
import { useAuthentication, useOutsideClick } from '../utils'
import { AuthContext, signOut } from '../auth'
import { ReactComponent as AccountIcon } from '../icons/accountRounded.svg'
import { ReactComponent as LogoutIcon } from '../icons/logout.svg'
import { AppRoutes } from '../appRoutes'
import { useTranslation } from 'react-i18next'

const CONTACT_INFO_URL = 'https://google.com'

const menuItemClass =
  'flex items-center gap-3.5 py-4 px-3 text-base text-aubergine border-b border-divider bg-bgMenu hover:bg-bgAccountButton w-full'

export const Layout = ({ children }: { children: ReactNode }) => {
  const { authentication } = useContext(AuthContext)
  const { isAuthenticated, isAdmin } = useAuthentication()
  const [menuOpen, setMenuOpen] = useState(false)
  const { t } = useTranslation()

  const closeMenu = () => setMenuOpen(false)
  const ref = useOutsideClick(closeMenu)

  return (
    <div className="max-w-[1536px] mx-auto pt-6 pb-12 md:py-12 px-4 sm:px-6 flex flex-col min-h-screen">
      <div className="flex justify-between">
        <Link to="/">
          <Logo />
        </Link>
        {isAuthenticated && (
          <div className="relative z-10" ref={ref}>
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="flex items-center justify-center gap-2 px-4 py-2.5 rounded-full text-sm font-medium w-[inherit] text-primary bg-none sm:bg-bgAccountButton sm:hover:shadow-button hover:scale-sc-button-hover"
            >
              <AccountIcon />
              {authentication?.email}
            </button>
            {menuOpen && (
              <div
                className={`shadow-dialog rounded absolute right-0 ${isAdmin ? '-bottom-[100px]' : '-bottom-12'}`}
                aria-labelledby="hs-dropdown-default"
              >
                {isAdmin && (
                  <Link className={menuItemClass} to={AppRoutes.users} onClick={closeMenu}>
                    <AccountIcon />
                    {t('users')}
                  </Link>
                )}
                <button className={menuItemClass} onClick={signOut}>
                  <LogoutIcon />
                  {t('logout')}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-12 flex flex-1 justify-center">{children}</div>
      <div className="flex flex-col items-center gap-4 mt-8">
        <div className="flex gap-11">
          <Link to={AppRoutes.privacy} className="text-sm text- text-secondary font-medium">
            {t('policy')}
          </Link>
          <Link to={CONTACT_INFO_URL} target="_blank" rel="noreferrer" className="text-sm text-primary font-medium">
            {t('contactInfo')}
          </Link>
        </div>
      </div>
    </div>
  )
}
