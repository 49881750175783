import { AutocompleteInput, Button, Input, InputType, Spin } from '../components'
import { handleError } from '../utils'
import { FormEvent, useCallback, useContext, useState } from 'react'
import { ButtonTypes } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../appRoutes'
import { postReports } from './postReports'
import { SME, SMEContext, SocialMediaPlatforms, socialMedia } from './sme-context'
import { useTranslation } from 'react-i18next'
import { FetchResult } from '../utils/fetchMutation'
import { ReactComponent as GlobeIcon } from '../icons/globe.svg'

export const CompetitorsLinkPage = () => {
  const {
    setCompetitorCompany1,
    setCompetitorCompany2,
    setCompetitorCompany3,
    setCompetitor1Data,
    setCompetitor2Data,
    setCompetitor3Data,
    competitorCompany1,
    competitorCompany2,
    competitorCompany3,
  } = useContext(SMEContext)
  const [formValues, setFormValues] = useState({
    ig1: competitorCompany1.value || '',
    tiktok1: competitorCompany1.value || '',
    twitter1: competitorCompany1.value || '',
    youtube1: competitorCompany1.value || '',
    linkedin1: competitorCompany1.value || '',
    ig2: competitorCompany2.value || '',
    tiktok2: competitorCompany2.value || '',
    twitter2: competitorCompany2.value || '',
    youtube2: competitorCompany2.value || '',
    linkedin2: competitorCompany2.value || '',
    ig3: competitorCompany3.value || '',
    tiktok3: competitorCompany3.value || '',
    twitter3: competitorCompany3.value || '',
    youtube3: competitorCompany3.value || '',
    linkedin3: competitorCompany3.value || '',
  })
  const [formLabels, setFormLabels] = useState({
    'ig1-label': competitorCompany1.label || '',
    'tiktok1-label': competitorCompany1.label || '',
    'twitter1-label': competitorCompany1.label || '',
    'youtube1-label': competitorCompany1.label || '',
    'linkedin1-label': competitorCompany1.label || '',
    'ig2-label': competitorCompany2.label || '',
    'tiktok2-label': competitorCompany2.label || '',
    'twitter2-label': competitorCompany2.label || '',
    'youtube2-label': competitorCompany2.label || '',
    'linkedin2-label': competitorCompany2.label || '',
    'ig3-label': competitorCompany3.label || '',
    'tiktok3-label': competitorCompany3.label || '',
    'twitter3-label': competitorCompany3.label || '',
    'youtube3-label': competitorCompany3.label || '',
    'linkedin3-label': competitorCompany3.label || '',
  })
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      setLoading(true)
      const promises: Promise<FetchResult<SME>>[] = []
      const competitor1 =
        formValues.ig1 || formValues.tiktok1 || formValues.twitter1 || formValues.linkedin1 || formValues.youtube1
      const competitor2 =
        formValues.ig2 || formValues.tiktok2 || formValues.twitter2 || formValues.linkedin2 || formValues.youtube2
      const competitor3 =
        formValues.ig3 || formValues.tiktok3 || formValues.twitter3 || formValues.linkedin3 || formValues.youtube3

      if (competitor1 !== '')
        promises.push(
          postReports({
            ig: formValues.ig1,
            tiktok: formValues.tiktok1,
            twitter: formValues.twitter1,
            linkedin: formValues.linkedin1,
            youtube: formValues.youtube1,
            withoutSummary: true,
          })
        )

      if (competitor2 !== '')
        promises.push(
          postReports({
            ig: formValues.ig2,
            tiktok: formValues.tiktok2,
            twitter: formValues.twitter2,
            linkedin: formValues.linkedin2,
            youtube: formValues.youtube2,
            withoutSummary: true,
          })
        )

      if (competitor3 !== '')
        promises.push(
          postReports({
            ig: formValues.ig3,
            tiktok: formValues.tiktok3,
            twitter: formValues.twitter3,
            linkedin: formValues.linkedin3,
            youtube: formValues.youtube3,
            withoutSummary: true,
          })
        )

      setCompetitorCompany1({
        value:
          formValues.ig1 || formValues.tiktok1 || formValues.twitter1 || formValues.linkedin1 || formValues.youtube1,
        label:
          formLabels['ig1-label'] ||
          formLabels['tiktok1-label'] ||
          formLabels['twitter1-label'] ||
          formLabels['linkedin1-label'] ||
          formLabels['youtube1-label'],
      })
      setCompetitorCompany2({
        value:
          formValues.ig2 || formValues.tiktok2 || formValues.twitter2 || formValues.linkedin2 || formValues.youtube2,
        label:
          formLabels['ig2-label'] ||
          formLabels['tiktok2-label'] ||
          formLabels['twitter2-label'] ||
          formLabels['linkedin2-label'] ||
          formLabels['youtube2-label'],
      })
      setCompetitorCompany3({
        value:
          formValues.ig3 || formValues.tiktok3 || formValues.twitter3 || formValues.linkedin3 || formValues.youtube3,
        label:
          formLabels['ig3-label'] ||
          formLabels['tiktok3-label'] ||
          formLabels['twitter3-label'] ||
          formLabels['linkedin3-label'] ||
          formLabels['youtube3-label'],
      })

      return Promise.all(promises)
        .then((results) => {
          results.forEach((result, index) => {
            const checkData = () => {
              if (result?.data && Object.values(result.data).every((value) => !value)) {
                const competitorCompany = index === 0 ? competitor1 : index === 1 ? competitor2 : competitor3
                alert(t('noDataFor', { company: competitorCompany }))
              }
            }

            if (competitor1 !== '' && index === 0) {
              setCompetitor1Data(result?.data)
              checkData()
            }
            if ((competitor2 !== '' && index === 1) || (competitor1 === '' && index === 0)) {
              setCompetitor2Data(result?.data)
              checkData()
            }
            if (
              (competitor3 !== '' && index === 2) ||
              (competitor1 === '' && competitor2 === '' && index === 0) ||
              (competitor2 === '' && index === 1) ||
              (competitor1 === '' && index === 1)
            ) {
              setCompetitor3Data(result?.data)
              checkData()
            }
          })
        }, handleError)
        .finally(() => {
          setLoading(false)
          navigate(AppRoutes.compare)
        })
    },
    [
      formValues,
      formLabels,
      setCompetitorCompany1,
      setCompetitorCompany2,
      setCompetitorCompany3,
      t,
      setCompetitor1Data,
      setCompetitor2Data,
      setCompetitor3Data,
      navigate,
    ]
  )

  return (
    <div className="max-w-[840px] flex flex-col items-center relative">
      <h2 className="text-primary text-[1.75rem] text-center mb-9">{t('typeCompetitorUrl')}</h2>
      <form onSubmit={handleSubmit}>
        <div className="flex gap-4 justify-center flex-wrap w-full">
          {[1, 2, 3].map((index) => (
            <div key={index}>
              <p>{t('competitor', { index })}</p>
              {socialMedia.map((platform) =>
                [SocialMediaPlatforms.ig, SocialMediaPlatforms.linkedin, SocialMediaPlatforms.tiktok].includes(
                  platform
                ) ? (
                  <Input
                    key={platform}
                    icon={<GlobeIcon />}
                    label={t(platform)}
                    type={InputType.text}
                    name={platform}
                    value={formValues[`${platform}${index}` as keyof typeof formValues]}
                    onChange={(event) => {
                      setFormValues({ ...formValues, [`${platform}${index}`]: event?.target.value })
                      setFormLabels({ ...formLabels, [`${platform}${index}-label`]: event.target.value })
                    }}
                    labelBg="before:bg-[#E9E1ED]"
                    placeholder={t(platform)}
                    bottomInfo={t('provideExactId')}
                  />
                ) : (
                  <AutocompleteInput
                    key={`${platform}${index}`}
                    platform={platform}
                    name={`${platform}${index}`}
                    selectedValue={{
                      label: formLabels[`${platform}${index}-label` as keyof typeof formLabels],
                      value: formValues[`${platform}${index}` as keyof typeof formValues],
                    }}
                    onSelect={(value: { label: string; value: string }) => {
                      setFormValues({ ...formValues, [`${platform}${index}`]: value.value })
                      setFormLabels({ ...formLabels, [`${platform}${index}-label`]: value.label })
                    }}
                  />
                )
              )}
            </div>
          ))}
        </div>
        {loading ? (
          <div className="flex justify-center">
            <Spin />
          </div>
        ) : (
          <Button
            buttonType={ButtonTypes.primary}
            title={t('check')}
            styles="flex justify-center"
            type="submit"
            disabled={Object.values(formValues).every((value) => !value)}
          />
        )}
      </form>
    </div>
  )
}
