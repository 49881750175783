import { AppRoutes } from '../appRoutes'
import { Button, ButtonTypes, ContentBox, DropdownCompetitors, Heading, SelectorSocialMedia } from '../components'
import { ReactComponent as GlobeIcon } from '../icons/globe.svg'
import { ReactComponent as EyeIcon } from '../icons/eye.svg'
import { ReactComponent as AccountIcon } from '../icons/account.svg'
import { CompareChart, CompareData } from './compare-chart'
import { CompetitorsItemSelect } from './competitors-item-select'
import { useContext, useEffect, useState } from 'react'
import { SME, SMEContext } from '../sme/sme-context'
import { SocialMedia } from '../components/selectorSocialMedia/selectorSocialMedia'
import { Navigate } from 'react-router-dom'
import { CompetitorsLinkPage } from '../sme'
import { useTranslation } from 'react-i18next'

export const ComparePage = () => {
  const {
    data,
    company,
    competitor1Data,
    competitorCompany1,
    competitor2Data,
    competitor3Data,
    competitorCompany2,
    competitorCompany3,
    setCompetitor1Data,
    setCompetitor2Data,
    setCompetitor3Data,
  } = useContext(SMEContext)
  const [selectedSocialMedia, setSelectedSocialMedia] = useState(data ? Object.keys(data)[0] : SocialMedia.youtube)
  const { t } = useTranslation()
  const [selectedCompetitor, setSelectedCompetitor] = useState(
    competitorCompany1.label || competitorCompany2.label || competitorCompany3.label
  )
  const competitors: Record<string, SME | undefined> = { competitor1Data, competitor2Data, competitor3Data }

  useEffect(() => {
    if (!selectedCompetitor) {
      setSelectedCompetitor(competitorCompany1.label || competitorCompany2.label || competitorCompany3.label)
    }
  }, [competitorCompany1, competitorCompany2, competitorCompany3, selectedCompetitor])

  if (!data || Object.values(data).every((value) => !value)) {
    return <Navigate to={AppRoutes.home} />
  }

  if (Object.values(competitors).every((data) => !data || Object.values(data).every((value) => !value))) {
    return <CompetitorsLinkPage />
  }

  const typedSelectedSocialMedia = selectedSocialMedia as keyof SME
  const allCompetitorsData = [
    {
      posts: [
        {
          name: company,
          value: Number(data?.[typedSelectedSocialMedia]?.statistic?.postCount),
        },
        {
          name: competitorCompany1.label,
          value: Number(competitor1Data?.[typedSelectedSocialMedia]?.statistic?.postCount),
        },
        {
          name: competitorCompany2.label,
          value: Number(competitor2Data?.[typedSelectedSocialMedia]?.statistic?.postCount),
        },
        {
          name: competitorCompany3.label,
          value: Number(competitor3Data?.[typedSelectedSocialMedia]?.statistic?.postCount),
        },
      ],
    },
    {
      subscribers: [
        {
          name: company,
          value: Number(data?.[typedSelectedSocialMedia]?.statistic?.subscriberCount),
        },
        {
          name: competitorCompany1.label,
          value: Number(competitor1Data?.[typedSelectedSocialMedia]?.statistic?.subscriberCount),
        },
        {
          name: competitorCompany2.label,
          value: Number(competitor2Data?.[typedSelectedSocialMedia]?.statistic?.subscriberCount),
        },
        {
          name: competitorCompany3.label,
          value: Number(competitor3Data?.[typedSelectedSocialMedia]?.statistic?.subscriberCount),
        },
      ],
    },
    {
      views: [
        {
          name: company,
          value: Number(data?.[typedSelectedSocialMedia]?.statistic?.viewCount),
        },
        {
          name: competitorCompany1.label,
          value: Number(competitor1Data?.[typedSelectedSocialMedia]?.statistic?.viewCount),
        },
        {
          name: competitorCompany2.label,
          value: Number(competitor2Data?.[typedSelectedSocialMedia]?.statistic?.viewCount),
        },
        {
          name: competitorCompany3.label,
          value: Number(competitor3Data?.[typedSelectedSocialMedia]?.statistic?.viewCount),
        },
      ],
    },
  ]

  const selectedCompetitorData = () => {
    if (selectedCompetitor === competitorCompany1.label) return competitor1Data
    if (selectedCompetitor === competitorCompany2.label) return competitor2Data
    if (selectedCompetitor === competitorCompany3.label) return competitor3Data
    return competitor1Data || competitor2Data || competitor3Data
  }

  const preparedData = (data: SME | undefined) =>
    data &&
    ['instagram', 'twitter', 'youtube', 'tiktok', 'linkedin'].map((value) => ({
      company: value,
      value: data[value as keyof SME],
    }))

  const toNumber = (value: unknown) => {
    const numberValue = Number(value)
    if (numberValue || numberValue === 0) return numberValue
    return t('noOrWrongId')
  }

  const mappedData = [
    {
      icon: <GlobeIcon />,
      header: t('numberOfPosts'),
      company,
      competitor: selectedCompetitor || competitorCompany1.label,
      companyStatistics: preparedData(data)
        ?.map(({ company, value }) => ({
          company,
          value: toNumber(value?.statistic?.postCount),
        }))
        .sort((a, b) => (a.company > b.company ? 1 : -1)),
      competitorStatistics: preparedData(selectedCompetitorData())
        ?.map(({ company, value }) => ({
          company,
          value: toNumber(value?.statistic?.postCount),
        }))
        .sort((a, b) => (a.company > b.company ? 1 : -1)),
    },
    {
      icon: <AccountIcon />,
      header: t('numberOfSubscribers'),
      company,
      competitor: selectedCompetitor || competitorCompany1.label,
      companyStatistics: preparedData(data)
        ?.map(({ company, value }) => ({
          company,
          value: toNumber(value?.statistic?.subscriberCount),
        }))
        .sort((a, b) => (a.company > b.company ? 1 : -1)),
      competitorStatistics: preparedData(selectedCompetitorData())
        ?.map(({ company, value }) => ({
          company,
          value: toNumber(value?.statistic?.subscriberCount),
        }))
        .sort((a, b) => (a.company > b.company ? 1 : -1)),
    },
    {
      icon: <EyeIcon />,
      header: t('numberOfViews'),
      company,
      competitor: selectedCompetitor || competitorCompany1.label,
      companyStatistics: preparedData(data)
        ?.map(({ company, value }) => ({
          company,
          value: toNumber(value?.statistic?.viewCount),
        }))
        .sort((a, b) => (a.company > b.company ? 1 : -1)),
      competitorStatistics: preparedData(selectedCompetitorData())
        ?.map(({ company, value }) => ({
          company,
          value: toNumber(value?.statistic?.viewCount),
        }))
        .sort((a, b) => (a.company > b.company ? 1 : -1)),
    },
  ]

  const resetCompetitors = () => {
    setCompetitor1Data(undefined)
    setCompetitor2Data(undefined)
    setCompetitor3Data(undefined)
  }

  return (
    <div className="flex flex-col w-full items-center">
      <ContentBox>
        <div className="flex items-end flex-wrap mb-4 w-full">
          <Heading>
            <span className="mr-4">{company}</span>
          </Heading>
          <DropdownCompetitors
            onSelect={setSelectedCompetitor}
            selectedValue={
              selectedCompetitor || competitorCompany1.label || competitorCompany2.label || competitorCompany3.label
            }
            values={[competitorCompany1.label, competitorCompany2.label, competitorCompany3.label].filter(
              (value) => value !== ''
            )}
          />
        </div>
        <p className="max-w-[500px] mb-10 md:font-medium text-sm md:text-base text-darkPurple">
          {t('compareDescription', { company, competitor: selectedCompetitor || competitorCompany1 })}
        </p>
        {mappedData.map((info, index) => (
          <CompareChart key={index} data={info as CompareData} />
        ))}
      </ContentBox>
      <div className="mt-[60px] flex flex-wrap items-center justify-start">
        <div className="flex gap-1">
          <p className="text-[22px] text-primary">{company}</p>
          <p className="text-[22px] text-darkPurple mr-[20px] mb-2 md:mb-0">
            {t('vs')} {t('allCompetitorsOn')}
          </p>
        </div>
        <div className="flex justify-center w-full md:w-auto">
          <SelectorSocialMedia
            selectedSocialMedia={selectedSocialMedia}
            setSelectedSocialMedia={setSelectedSocialMedia}
          />
        </div>
      </div>
      <div className="mt-[30px] flex items-center flex-wrap justify-center md:justify-start gap-4">
        {allCompetitorsData.map((allData, index) => {
          const preparedData = Object.entries(allData!).map(([key, value]) => ({
            data: value,
            title:
              key === 'posts'
                ? t('numberOfPosts')
                : key === 'subscribers'
                ? t('numberOfSubscribers')
                : `${t('numberOfViews')}:`,
          }))
          if (preparedData[0].data.every((data: { value?: string }) => !data.value)) return null
          return (
            <div>
              <CompetitorsItemSelect key={index} chartData={preparedData[0]} />
            </div>
          )
        })}
      </div>
      <div className="flex gap-2 mt-10">
        <Button buttonType={ButtonTypes.secondary} title={t('editCompetitors')} onClick={resetCompetitors} />
        <Button buttonType={ButtonTypes.secondary} title={t('goBackToSme')} to={AppRoutes.sme} />
      </div>
    </div>
  )
}
