import { Recommendation } from './recommendation-page'

type Props = {
  recommendation: Recommendation
  index: number
}

export const RecommendationItem = ({ index, recommendation }: Props) => {
  return (
    <div className="w-full flex flex-row mb-5 drop-shadow-md">
      <div className="flex items-center bg-primary w-[75px] rounded-l-[24px]">
        <p className="text-center w-full text-white text-[32px]">{index}</p>
      </div>
      <div className="px-3 py-2 md:py-5 md:px-7 w-full flex bg-white items-center rounded-r-[24px]">
        <p className="w-full text-l text-secondary text-sm md:text-base">{`${recommendation.title}: ${recommendation.description}`}</p>
      </div>
    </div>
  )
}
