import { ReactComponent as UnselectedFacebook } from '../../icons/unselectedFacebook.svg'
import { ReactComponent as SelectedFacebook } from '../../icons/selectedFacebook.svg'
import { ReactComponent as UnselectedInstagram } from '../../icons/unselectedInstagram.svg'
import { ReactComponent as SelectedInstagram } from '../../icons/selectedInstagram.svg'
import { ReactComponent as UnselectedLinkedin } from '../../icons/unselectedLinkedin.svg'
import { ReactComponent as SelectedLinkedin } from '../../icons/selectedLinkedin.svg'
import { ReactComponent as UnselectedTikTok } from '../../icons/unselectedTikTok.svg'
import { ReactComponent as SelectedTikTok } from '../../icons/selectedTikTok.svg'
import { ReactComponent as UnselectedTwitter } from '../../icons/unselectedTwitter.svg'
import { ReactComponent as SelectedTwitter } from '../../icons/selectedTwitter.svg'
import { ReactComponent as UnselectedYoutube } from '../../icons/unselectedYoutube.svg'
import { ReactComponent as SelectedYoutube } from '../../icons/selectedYoutube.svg'
import { ReactNode } from 'react'
import { SocialMedia } from '../selectorSocialMedia/selectorSocialMedia'

export const SelectableIcon = ({ platform, isSelected }: { platform: string; isSelected: boolean }) => {
  const icons: Record<SocialMedia, ReactNode> = {
    [SocialMedia.facebook]: isSelected ? <SelectedFacebook width={40} /> : <UnselectedFacebook width={40} />,
    [SocialMedia.instagram]: isSelected ? <SelectedInstagram width={40} /> : <UnselectedInstagram width={40} />,
    [SocialMedia.youtube]: isSelected ? <SelectedYoutube width={40} /> : <UnselectedYoutube width={40} />,
    [SocialMedia.linkedin]: isSelected ? <SelectedLinkedin width={40} /> : <UnselectedLinkedin width={40} />,
    [SocialMedia.twitter]: isSelected ? <SelectedTwitter width={40} /> : <UnselectedTwitter width={40} />,
    [SocialMedia.tiktok]: isSelected ? <SelectedTikTok width={40} /> : <UnselectedTikTok width={40} />,
  }
  const IconComponent = icons[platform as SocialMedia]
  return <div>{IconComponent}</div>
}
