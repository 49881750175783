import { ReactNode } from 'react'
import { Dropdown, Period } from '../components/dropdown'

export const StatisticsSegment = ({
  icon,
  header,
  children,
  handleSelect,
  selectedValue,
  withSelect,
}: {
  icon: ReactNode
  header: string
  children: ReactNode
  handleSelect?: (value: Period) => void
  selectedValue?: Period
  withSelect?: boolean
}) => (
  <div>
    <div className="flex items-center gap-6 mb-5">
      {icon}
      <p className="font-medium text-darkPurple">{header}</p>
      {withSelect && handleSelect && selectedValue && (
        <Dropdown onSelect={handleSelect} selectedValue={selectedValue} values={['day', 'week', 'month', 'year']} />
      )}
    </div>
    {children}
  </div>
)
