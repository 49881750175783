import { apiUrls } from '../api/api-urls'
import { fetchMutation } from '../utils'

type CreateAccountBody = {
  email: string
  password: string
}

export const postCreateAccount = (body: CreateAccountBody) =>
  fetchMutation(apiUrls.createAccount, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
