import { useContext } from 'react'
import { apiUrls } from '../api/api-urls'
import { fetchMutation, handleError } from '../utils'
import { UsersContext } from './users-context'

export const useResetUserPassword = () => {
  const { setSelectedUser, selectedUser } = useContext(UsersContext)
  return () =>
    fetchMutation(`${apiUrls.users}/${selectedUser?.id}/reset-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(() => {
      setSelectedUser(undefined)
    }, handleError)
}
