import { BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { socialMediaColors, socialMediaIcons } from './social-media-icons'
import { useIsMobile } from '../utils'

export const HorizontalChart = ({ data }: { data: any[] }) => {
  const isMobile = useIsMobile(767)
  const CategoryTick = (props: any) => {
    return (
      <foreignObject x={props.x - 36} y={props.y - 20} width={40} height={40}>
        {socialMediaIcons()[props.payload.value]}
      </foreignObject>
    )
  }

  const CustomLabel = (props: any) => {
    const { x, value, width } = props
    const y = isMobile ? props.y - 6 : props.y
    return typeof value === 'number' ? (
      <text x={x} y={y} dx={width / 2 - 5} dy={25} fill="#fff" fontSize={isMobile ? 16 : 22} textAnchor="middle">
        {value}
      </text>
    ) : (
      <text x={70} y={y} dy={25} fill="#000" fontSize={16}>
        {value}
      </text>
    )
  }

  const highestValue = Math.max(...data.filter(({ value }) => typeof value === 'number').map(({ value }) => value))

  return (
    <div style={{ width: '100%', height: '100%', marginLeft: isMobile ? 8 : -8 }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} layout="vertical">
          <XAxis hide axisLine={false} type="number" />
          <YAxis
            yAxisId={0}
            dataKey={'company'}
            type="category"
            axisLine={false}
            tickLine={false}
            width={61}
            padding={{ bottom: 16 }}
            tickMargin={24}
            tick={CategoryTick}
          />
          <Bar
            dataKey={'value'}
            minPointSize={String(highestValue).length * 14}
            barSize={isMobile ? 24 : 36}
            radius={20}
            label={<CustomLabel />}
          >
            {data.map(({ company }) => {
              return <Cell key={company} fill={socialMediaColors[company]} />
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
