import { Button, AuthContentBox, Input, InputType } from '../components'
import { handleError } from '../utils'
import { passwordPattern } from './patterns'
import { ReactComponent as LockIcon } from '../icons/lock.svg'
import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { ButtonTypes } from '../components/Button'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { getInfoFromAuthToken } from '../utils/getInfoFromAuthToken'
import { AppRoutes } from '../appRoutes'
import { useTranslation } from 'react-i18next'
import { postResetPassword } from './postResetPassword'

export const ChangePasswordPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: '',
  })
  const [isSending, setIsSending] = useState(false)
  const { t } = useTranslation()
  const isSamePassword = formValues.confirmPassword === formValues.password

  const token = searchParams.get('token') || ''
  const { email } = getInfoFromAuthToken(token)

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      setIsSending(true)
      postResetPassword({ email, password: formValues.password, token })
        .then(() => {
          navigate(AppRoutes.home)
        }, handleError)
        .finally(() => setIsSending(false))
    },
    [email, formValues.password, navigate, token]
  )

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setFormValues({ ...formValues, [event.target.name]: event.target.value }),
    [formValues]
  )

  if (!token) {
    return <Navigate to={AppRoutes.home} />
  }

  return (
    <AuthContentBox title={t('changePassword')}>
      <form className="w-[360px]" onSubmit={handleSubmit}>
        <Input
          icon={<LockIcon />}
          type={InputType.password}
          label={t('newPassword')}
          name="password"
          required
          pattern={passwordPattern}
          value={formValues.password}
          placeholder={t('newPassword')}
          bottomInfo={t('minCharPassword')}
          onChange={handleOnChange}
        />
        <Input
          icon={<LockIcon />}
          type={InputType.password}
          label={t('confirmPassword')}
          name="confirmPassword"
          required
          pattern={passwordPattern}
          value={formValues.confirmPassword}
          placeholder={t('confirmPassword')}
          onChange={handleOnChange}
          error={formValues.confirmPassword && !isSamePassword ? t('pleaseEnterSamePassword') : undefined}
        />
        <Button
          buttonType={ButtonTypes.primary}
          title={t('saveChanges')}
          styles="flex justify-center w-full"
          type="submit"
          disabled={Object.values(formValues).some((value) => !value) || !isSamePassword || isSending}
        />
      </form>
    </AuthContentBox>
  )
}
