import { useState, useEffect, useCallback } from 'react'

export const useIsMobile = (maxWidth?: number) => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [handleWindowSizeChange])

  return width <= (maxWidth || 640)
}
