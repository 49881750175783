import { Navigate, Route, Routes } from 'react-router-dom'
import './app.css'
import { ForgotPasswordPage, ChangePasswordPage, LoginPage, ResetPasswordPage, NewAccountPage } from './auth'
import { Layout } from './components'
import { AppRoutes } from './appRoutes'
import { useAuthentication } from './utils'
import { SMELinkPage, SMEPage } from './sme'
import { RecommendationPage } from './recommendation/recommendation-page'
import { ComparePage } from './compare'
import { SMEProvider } from './sme/sme-context'
import { UsersListPage } from './users'
import { PrivacyPage } from './privacy/privacy-page'

export default function App() {
  const { isAuthenticated, isAdmin } = useAuthentication()
  const homePageRedirect = <Navigate to={AppRoutes.home} />

  return (
    <Layout>
      <SMEProvider>
        <Routes>
          <Route
            path={AppRoutes.home}
            element={isAuthenticated ? <SMELinkPage /> : <Navigate to={AppRoutes.login} />}
          />
          <Route path={AppRoutes.login} element={isAuthenticated ? homePageRedirect : <LoginPage />} />
          <Route path={AppRoutes.passwordReset} element={isAuthenticated ? homePageRedirect : <ResetPasswordPage />} />
          <Route
            path={AppRoutes.forgotPassword}
            element={isAuthenticated ? homePageRedirect : <ForgotPasswordPage />}
          />
          <Route path={AppRoutes.changePassword} element={<ChangePasswordPage />} />
          <Route path={AppRoutes.newAccount} element={isAuthenticated ? homePageRedirect : <NewAccountPage />} />
          <Route path={AppRoutes.sme} element={isAuthenticated ? <SMEPage /> : homePageRedirect} />
          <Route
            path={AppRoutes.recommendation}
            element={isAuthenticated ? <RecommendationPage /> : homePageRedirect}
          />
          <Route path={AppRoutes.compare} element={isAuthenticated ? <ComparePage /> : homePageRedirect} />
          <Route path={AppRoutes.privacy} element={<PrivacyPage />} />
          <Route path={AppRoutes.users} element={isAuthenticated && isAdmin ? <UsersListPage /> : homePageRedirect} />
          <Route path="*" element={homePageRedirect} />
        </Routes>
      </SMEProvider>
    </Layout>
  )
}
