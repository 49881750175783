import { Button, DeleteConfirmation, PageContainer, Spin, UsersTable } from '../components'
import { ButtonTypes } from '../components/Button'
import { handleError } from '../utils'
import { ReactComponent as IconPlus } from '../icons/plus.svg'
import { fetchUsers } from './fetchUsers'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AddUser } from './add-user'
import { UsersContext } from './users-context'
import { UpdateUser } from './update-user'
import { useDeleteUser } from './deleteUser'
import { useTranslation } from 'react-i18next'

export const UsersListPage = () => {
  const { users, selectedUser, setUsers, setSelectedUser } = useContext(UsersContext)
  const [showAddUser, setShowAddUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const deleteUser = useDeleteUser()
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
    fetchUsers()
      .then((users) => {
        setUsers(users)
        setLoading(false)
      }, handleError)
      .finally(() => setLoading(false))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const showAddUserPopup = useCallback(() => {
    setShowAddUser(true)
  }, [])

  const hideAdUserPopup = useCallback(() => {
    setShowAddUser(false)
  }, [])

  const closePopup = useCallback(() => {
    setSelectedUser(undefined)
  }, [setSelectedUser])

  return (
    <PageContainer title={t('listOfUsers')}>
      {loading ? (
        <div className="w-full flex justify-center pt-8">
          <Spin />
        </div>
      ) : (
        <>
          <UsersTable users={users} />
          <Button
            onClick={showAddUserPopup}
            title={t('add')}
            buttonType={ButtonTypes.primary}
            icon={<IconPlus />}
            styles="flex justify-end mt-[37px]"
          />
          {showAddUser && <AddUser close={hideAdUserPopup} />}
          {selectedUser &&
            (selectedUser.toDelete ? (
              <DeleteConfirmation
                close={closePopup}
                onConfirm={() => deleteUser(selectedUser!.id)}
                title={t('sureToDeleteUser')}
              />
            ) : (
              <UpdateUser close={closePopup} />
            ))}
        </>
      )}
    </PageContainer>
  )
}
