import { ReactComponent as EditIcon } from '../../icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../icons/trash.svg'
import { TableProps, User } from './types'
import { UsersContext } from '../../users'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmark.svg'
import { ReactComponent as XIcon } from '../../icons/xmark.svg'

const TableHead = ({ value, styles }: { value: string; styles?: string }) => (
  <th scope="col" className={`px-4 py-3.5 ${styles}`}>
    {value}
  </th>
)

const TableRow = ({ user }: { user: User }) => {
  const { firstname, lastname, email, expired } = user
  const { setSelectedUser } = useContext(UsersContext)
  const { t } = useTranslation()

  const handleDeleteUser = useCallback(() => setSelectedUser({ ...user, toDelete: true }), [setSelectedUser, user])
  const handleUpdateUser = useCallback(() => setSelectedUser(user), [setSelectedUser, user])

  return (
    <tr className="border-b border-grey text-base">
      <td className="px-4 py-3">{firstname}</td>
      <td className="px-4 py-3">{lastname}</td>
      <td className="px-4 py-3">{email}</td>
      <td className="px-4 py-3">
        <div className="flex justify-center">
          {expired ? (
            <XIcon color="red" title={t('expired')} />
          ) : (
            <CheckmarkIcon color="mediumseagreen" title={t('active')} />
          )}
        </div>
      </td>
      <td className="px-4 py-3 w-[100px]">
        <div className="flex gap-4">
          <EditIcon className="cursor-pointer" onClick={handleUpdateUser} />
          <DeleteIcon className="cursor-pointer" onClick={handleDeleteUser} />
        </div>
      </td>
    </tr>
  )
}
export const TableDesktop = ({ users }: TableProps) => {
  const { t } = useTranslation()
  return (
    <div className="relative overflow-x-auto w-full">
      <table className="w-full text-sm text-left text-aubergine bg-transparent">
        <thead className="text-xs text-aubergine font-semibold">
          <tr className="border-b border-grey text-sm">
            <TableHead value={t('firstname')} />
            <TableHead value={t('lastname')} />
            <TableHead value={t('email')} />
            <TableHead value={t('active')} styles="flex justify-center" />
            <TableHead value={t('actions')} />
          </tr>
        </thead>
        <tbody>
          {users?.length === 0 ? (
            <tr className="border-b border-grey text-base py-4">
              <td className="px-4 py-3 text-center" colSpan={4}>
                {t('noUsers')}
              </td>
            </tr>
          ) : (
            users?.map((user) => <TableRow key={user.id} user={user} />)
          )}
        </tbody>
      </table>
    </div>
  )
}
