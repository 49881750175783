import { PieChart as RechartsPieChart, Pie, Cell } from 'recharts'
import { socialMediaColors, socialMediaIcons } from './social-media-icons'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '../utils'

export const PieChart = ({ data, units }: { data: any[]; units: string }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile(899)
  const Label = (props: any) => {
    const RADIAN = Math.PI / 180
    const radius = props.innerRadius + (props.outerRadius - props.innerRadius) * 0.9
    const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN)
    const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN)
    return props.payload.value !== '-' ? (
      <foreignObject x={x - 50} y={y - 30} width={100} height={75}>
        <div className="flex flex-col items-center gap-1 p-1 bg-white shadow-chartItems rounded-lg">
          {socialMediaIcons(18, 18)[props.payload.company]}
          <div className="flex gap-1 items-end">
            {props.payload.value !== '-' ? (
              <>
                <p className="text-sm font-medium">{props.payload.value}</p>
                <p className="text-xs leading-5">{units}</p>
              </>
            ) : (
              <p className="text-xs leading-3 text-center">{t('noOrWrongId')}</p>
            )}
          </div>
        </div>
      </foreignObject>
    ) : null
  }

  const filteredData = data.filter(({ value }) => typeof value === 'number')
  const smWithHighestValue = filteredData.reduce(
    (max, currentData) => (max.value > currentData.value ? max : currentData),
    {}
  )

  const summedValue = filteredData.reduce((partialSum, currentData) => {
    return partialSum + currentData.value
  }, 0)

  const noData = Object.values(data).every(({ value }) => value === '-' || value === 0)

  return (
    <div className={`flex ${noData ? ' justify-start' : 'justify-center'} relative`}>
      {noData ? (
        <p>{t('noData')}</p>
      ) : (
        <>
          <RechartsPieChart width={isMobile ? 360 : 500} height={isMobile ? 365 : 450}>
            <Pie
              dataKey="value"
              data={data}
              cx={isMobile ? 180 : 250}
              cy={isMobile ? 180 : 225}
              innerRadius={isMobile ? 80 : 115}
              outerRadius={isMobile ? 140 : 205}
              fill="#8884d8"
              stroke="none"
              label={<Label />}
              minAngle={0}
              labelLine={false}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={socialMediaColors[entry.company]} />
              ))}
            </Pie>
          </RechartsPieChart>
          <div
            className={`absolute top-[50%] left-[50%] -translate-x-[45%] -translate-y-2/4 transition-opacity opacity-0 hover:opacity-100`}
          >
            <div className="flex flex-col justify-center items-center lg:gap-2">
              {socialMediaIcons(44, 44)[smWithHighestValue.company]}
              <p className={`${isMobile ? 'text-4xl' : 'text-6xl'} text-primary font-black`}>
                {Math.floor((smWithHighestValue.value / summedValue) * 100)}%
              </p>
              <p className={`${isMobile ? 'text-sm' : 'text-[1.35rem]'} leading-9 text-secondary font-medium`}>
                of all your subs
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
