import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { UsersContext } from '.'
import { Button, ContentBox, Input, InputType, Popup, Spin, User } from '../components'
import { ButtonTypes } from '../components/Button'
import { useUpdateUser } from './updateUser'
import { useResetUserPassword } from './resetUserPassword'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

type Props = {
  close: () => void
}

type UserType = Partial<User>

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  start_date: '',
  end_date: '',
}

export const UpdateUser = ({ close }: Props) => {
  const [formValues, setFormValues] = useState<UserType>(initialValues)
  const [errors, setErrors] = useState<UserType>(initialValues)
  const updateUser = useUpdateUser()
  const resetUserPassword = useResetUserPassword()
  const { selectedUser, setSelectedUser } = useContext(UsersContext)
  const formRef = useRef(null)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      }),
    [formValues]
  )

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      setLoading(true)
      updateUser({
        id: selectedUser?.id || '',
        ...formValues,
        start_date: formValues.start_date ? String(dayjs(formValues.start_date).valueOf()) : undefined,
        end_date: formValues.end_date ? String(dayjs(formValues.end_date).valueOf()) : undefined,
      })
        .then(() => {
          setSelectedUser(undefined)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [selectedUser?.id, setSelectedUser, updateUser, formValues]
  )

  useEffect(() => {
    if (selectedUser) {
      setFormValues(selectedUser)
    }
  }, [selectedUser])

  const datesInputValidation = () =>
    setErrors({
      ...errors,
      start_date: dayjs(formValues.start_date).isAfter(dayjs(formValues.end_date))
        ? t('dateShouldBeEarlier')
        : undefined,
      end_date: dayjs(formValues.end_date).isBefore(dayjs(formValues.start_date)) ? t('dateShouldBeAfter') : undefined,
    })

  return (
    <Popup>
      <ContentBox padding="py-4 px-10">
        <h2 className="mb-2 mt-2 text-[2rem] text-center text-primary">{t('editUser')}</h2>
        <form className="w-full mb-4" onSubmit={handleSubmit} ref={formRef}>
          <Input
            label={t('firstname')}
            placeholder={t('firstname')}
            name="firstname"
            value={formValues.firstname}
            onChange={handleOnChange}
            onBlur={useCallback(() => {
              setErrors({
                ...errors,
                firstname: formValues.firstname ? undefined : t('pleaseFill', { value: t('firstname') }),
              })
            }, [errors, formValues.firstname, t])}
            error={errors.firstname}
          />
          <Input
            label={t('lastname')}
            placeholder={t('lastname')}
            name="lastname"
            value={formValues.lastname}
            onChange={handleOnChange}
            onBlur={useCallback(() => {
              setErrors({
                ...errors,
                lastname: formValues.lastname ? undefined : t('pleaseFill', { value: t('lastname') }),
              })
            }, [errors, formValues.lastname, t])}
            error={errors.lastname}
          />
          <Input
            label={t('email')}
            placeholder={t('email')}
            name="email"
            value={formValues.email}
            onChange={handleOnChange}
            onBlur={useCallback(() => {
              setErrors({
                ...errors,
                lastname: formValues.lastname ? undefined : t('pleaseFill', { value: t('email') }),
              })
            }, [errors, formValues.lastname, t])}
            error={errors.email}
          />
          <Input
            type={InputType.date}
            placeholder={t('startDate')}
            name="start_date"
            value={formValues.start_date}
            onChange={handleOnChange}
            label={t('startDate')}
            onBlur={datesInputValidation}
            error={errors.start_date}
          />
          <Input
            type={InputType.date}
            placeholder={t('endDate')}
            name="end_date"
            value={formValues.end_date}
            onChange={handleOnChange}
            label={t('endDate')}
            onBlur={datesInputValidation}
            error={errors.end_date}
          />
          <div className="flex flex-row justify-end w-full gap-5">
            {loading ? (
              <Spin />
            ) : (
              <Button
                buttonType={ButtonTypes.primary}
                title={t('update')}
                type="submit"
                disabled={Object.values(errors).some(Boolean)}
              />
            )}
            <Button
              buttonType={ButtonTypes.primary}
              title={t('passwordReset')}
              onClick={resetUserPassword}
              type="button"
              disabled={loading}
            />
            <Button buttonType={ButtonTypes.secondary} title={t('cancel')} onClick={close} disabled={loading} />
          </div>
        </form>
      </ContentBox>
    </Popup>
  )
}
