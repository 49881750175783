import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { emailPattern } from '../auth/patterns'
import { Button, ContentBox, Input, InputType, Popup, Spin, User } from '../components'
import { ButtonTypes } from '../components/Button'
import { handleError } from '../utils'
import { useCreateUser } from './createUser'
import { useTranslation } from 'react-i18next'

type Props = {
  close: () => void
}

type UserType = Partial<User>

const initialValues = {
  email: '',
  firstname: '',
  lastname: '',
}

export const AddUser = ({ close }: Props) => {
  const [formValues, setFormValues] = useState<UserType>(initialValues)
  const [errors, setErrors] = useState<UserType>(initialValues)
  const createUser = useCreateUser()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setFormValues({ ...formValues, [event.target.name]: event.target.value }),
    [formValues]
  )

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      setLoading(true)
      createUser(formValues)
        .then(() => {
          close()
        }, handleError)
        .finally(() => {
          setLoading(false)
        })
    },
    [createUser, formValues, close]
  )

  return (
    <Popup>
      <ContentBox padding="py-4 px-10">
        <h2 className="mb-2 mt-2 text-[1.5rem] text-center text-primary">{t('addUser')}</h2>
        <form className="w-full mb-4" onSubmit={handleSubmit}>
          <Input
            required
            placeholder={t('firstname')}
            name={'firstname'}
            onChange={handleOnChange}
            onBlur={useCallback(() => {
              setErrors({
                ...errors,
                firstname: formValues.firstname ? undefined : t('pleaseFill', { value: t('firstname') }),
              })
            }, [errors, formValues.firstname, t])}
            error={errors.firstname}
          />
          <Input
            required
            placeholder={t('lastname')}
            name={'lastname'}
            onChange={handleOnChange}
            onBlur={useCallback(() => {
              setErrors({
                ...errors,
                lastname: formValues.lastname ? undefined : t('pleaseFill', { value: t('lastname') }),
              })
            }, [errors, formValues.lastname, t])}
            error={errors.lastname}
          />
          <Input
            required
            pattern={emailPattern}
            placeholder={t('email')}
            name="email"
            type={InputType.email}
            onChange={handleOnChange}
            onBlur={useCallback(() => {
              setErrors({ ...errors, email: formValues.email ? undefined : t('pleaseFill', { value: t('email') }) })
            }, [errors, formValues.email, t])}
            error={errors.email}
          />
          <div className="flex flex-row justify-end w-full">
            <Button
              buttonType={ButtonTypes.primary}
              title={t('add')}
              styles={'mr-5'}
              type="submit"
              disabled={Object.values(errors).some(Boolean) || loading}
            />
            {loading ? (
              <div className="mr-5">
                <Spin />
              </div>
            ) : (
              <Button buttonType={ButtonTypes.primary} title={t('cancel')} onClick={close} />
            )}
          </div>
        </form>
      </ContentBox>
    </Popup>
  )
}
