import { PieChart as RechartsPieChart, Pie, Cell } from 'recharts'
import { ContentBox } from '../components'
const chartColors = ['#F15BB5', '#55ACEE', '#00F5D4', '#9B5DE5', '#000']
export const CompetitorsItemSelect = ({
  chartData,
}: {
  chartData: { title: string; data: { name: string; value: number }[] }
}) => {
  const { title, data } = chartData
  return (
    <ContentBox padding="p-4 pr-2">
      <div className="w-[320px] md:w-[350px] min-w-[300px] h-[215px]">
        <p className="font-medium text-darkPurple mb-[20px]">{title}</p>
        <div className="flex flex-row gap-2">
          <div className="mt-2">
            <RechartsPieChart width={145} height={145}>
              <Pie
                dataKey="value"
                data={data}
                cx={70}
                cy={70}
                innerRadius={40}
                outerRadius={70}
                fill="#8884d8"
                stroke="none"
                minAngle={0}
              >
                {data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={chartColors[index]} />
                ))}
              </Pie>
            </RechartsPieChart>
          </div>
          <div className="mt-[20px] w-full max-w-[165px] md:max-w-[195px]">
            {data.map((item, index) => {
              return (
                <div key={index} className="flex flex-row justify-between mb-3">
                  <p
                    style={{ color: chartColors[index] }}
                    className="text-sm text-ellipsis whitespace-nowrap overflow-hidden max-w-[100px]"
                  >
                    {item.name}
                  </p>
                  <p className="text-secondary text-base">{item.value || '-'}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </ContentBox>
  )
}
